import { reducerConst } from "../constants/storeConstants";
const initialState = {
  userDetails: {},///its for damble
  userFullDetails: {},// its for new user registering from login/signup (portal api user/profile/${adminID}`)
  // userLoader: false if required
  favCrypto: [],
  favFiat: [],
  favIsLoading: false,
  newsCategories: [],
  accounts: [],
  cryptoList: [],
  ipAddress: '',
  country: "",
  adminData: {},
  adminToken: "",
  stateList: [
    'Arunachal Pradesh', 'Bihar', 'Chhattisgarh', 'Goa', 'Gujarat', 'Haryana', 'Himachal Pradesh', 'Jharkhand', 'Karnataka', 'Kerala', 'Madhya Pradesh', 'Maharashtra', 'Manipur', 'Meghalaya', 'Mizoram', 'Punjab', 'Rajasthan', 'Tamil Nadu', 'Tripura', 'Uttar Pradesh', 'Uttarakhand', 'West Bengal', 'Andaman and Nicobar Islands', 'Dadra and Nagar Haveli', 'Daman and Diu', 'Chandigarh', 'Delhi', 'Jammu & Kashmir', 'Lakshadweep',
  ],
  betSlipData: {},
  matchId: '',
  myTeamData: [],
  fantasyUserData: {},
  matchId: '',
  joinedContest: [],
  selectedTeams: [],
  playerConditionsData: [],
  currentHomeTab: '',
  capAndViceCap: { //empty it after leaving the page
  },
  socialLinks: [],
  videoUrl: "",
  tempUser: ""
};

const
  Reducer = (state = initialState, action) => {
    switch (action.type) {
      case reducerConst.USER_DETAILS:
        return {
          ...state,
          userDetails: action.payload,
        };
      case reducerConst.JOINED_CONTEST:
        return {
          ...state,
          joinedContest: action.payload,
        };
      case reducerConst.TEAM_LIST:
        return {
          ...state,
          myTeamData: action.payload,
        };
      case reducerConst.FAV_CRYPTO:
        return {
          ...state,
          favCrypto: action.payload,
        };
      case reducerConst.FAV_FIAT:
        return {
          ...state,
          favFiat: action.payload,
        };
      case reducerConst.Fav_LOADING:
        return {
          ...state,
          favIsLoading: action.payload,
        };
      case reducerConst.NEWS_CATEGORIES:
        return {
          ...state,
          newsCategories: action.payload,
        };
      case reducerConst.PROFILE:
        return { ...state, accounts: action.payload };
      case reducerConst.CONVERTER:
        return { ...state, accounts: action.payload };
      case reducerConst.WALLET:
        return { ...state, accounts: action.payload };
      case reducerConst.WITHDRAW:
        return { ...state, accounts: action.payload };
      case reducerConst.SETTING:
        return { ...state, accounts: action.payload };
      case reducerConst.DEPOSITS:
        return { ...state, accounts: action.payload };
      case reducerConst.AFFILIATE:
        return { ...state, accounts: action.payload };
      case reducerConst.CRYPTO_LIST:
        return { ...state, cryptoList: action.payload };
      case reducerConst.IP_ADDRESS:
        return { ...state, ipAddress: action.payload };
      case reducerConst.COUNTRY_NAME:
        return { ...state, country: action.payload };
      case reducerConst.ADMIN_LOGGED_IN:
        return {
          ...state,
          adminData: action.payload.data.data,
          adminToken: action.payload.data.token
        };
      case reducerConst.ADMIN_LOGGED_OUT:
        return {
          ...state,
          adminData: {},
          adminToken: "",
        };
      case reducerConst.UPDATE_BET_SLIP:
        return {
          ...state,
          betSlipData: action.payload,
        };
      case reducerConst.FANTASY_ADD_USER_DATA:
        return {
          ...state,
          fantasyUserData: action.payload,
        };
      case reducerConst.STOCK_ADD_USER_DATA:
        return {
          ...state,
          stockUserData: action.payload,
        };
      case reducerConst.MATCH_ID:
        return {
          ...state,
          matchId: action.payload,
        };
      case reducerConst.SELECT_TEAM:
        return {
          ...state,
          selectedTeams: action.payload,
        };
      case reducerConst.PLAY_CONDITIONS:
        return {
          ...state,
          playerConditionsData: action.payload,
        };
      case reducerConst.CURRENT_HOME:
        return {
          ...state,
          currentHomeTab: action.payload,
        };
      case reducerConst.USER_FULL_DETAILS:
        return {
          ...state,
          userFullDetails: action.payload,
        };

      case reducerConst.SELECT_CAP_VICE_CAP:
        return {
          ...state,
          capAndViceCap: action.payload,
        };
      case reducerConst.SOCIAL_LINKS:
        return {
          ...state,
          socialLinks: action.payload
        }
      case reducerConst.VIDEO_URL:
        return {
          ...state,
          videoUrl: action.payload
        }
      case reducerConst.TEMP_USER:
        console.log("action.payload",action.payload)
        return {
          ...state,
          tempUser: action.payload
        }
      default:
        return state;
    }
  };

export default Reducer;
