export const reducerConst = {
  USER_DETAILS: "USER_DETAILS",
  FAV_CRYPTO: "FAV_CRYPTO",
  FAV_FIAT: "FAV_FIAT",
  Fav_LOADING: "Fav_LOADING",
  NEWS_CATEGORIES: "NEWS_CATEGORIES",
  PROFILE: "PROFILE",
  CONVERTER: "CONVERTER",
  WALLET: "WALLET",
  WITHDRAW: "WITHDRAW",
  SETTING: "SETTING",
  DEPOSITS: "DEPOSITS",
  AFFILIATE: "AFFILIATE",
  CRYPTO_LIST: "CRYPTO_LIST",
  TEAM_LIST: "TEAM_LIST",
  IP_ADDRESS: "IP_ADDRESS",
  COUNTRY_NAME: "COUNTRY_NAME",
  ADMIN_LOGGED_OUT:'admin-log-out',
  ADMIN_LOGGED_IN:'admin-login',
  UPDATE_BET_SLIP:"UPDATE_BET_SLIP",
  MATCH_ID: "MATCH_ID",
  USER_FULL_DETAILS: "USER_FULL_DETAILS",
  FANTASY_ADD_USER_DATA:"fantasy-add-user-data",
  MATCH_ID: "MATCH_ID",
  JOINED_CONTEST: "JOINED_CONTEST",
  SELECT_TEAM:"SELECT_TEAM",
  PLAY_CONDITIONS:"PLAY_CONDITIONS",
  CURRENT_HOME:"CURRENT_HOME",
  SELECT_CAP_VICE_CAP:"SELECT_CAP_VICE_CAP",
  STOCK_ADD_USER_DATA: "STOCK_ADD_USER_DATA",
  SOCIAL_LINKS : "SOCIAL_LINKS",
  VIDEO_URL:'VIDEO_URL',
  TEMP_USER:'TEMP_USER'
};

export const sagaConst = {
  FETCH_USER_DETAILS: "FETCH_USER_DETAILS",
};
