import React from 'react'
import NotificationIcon from './../../fantasyAssets/img/notificationIcon.png'
import WalletIcon from './../../fantasyAssets/img/walletIcon.png'
import BackBtnIcon from './../../fantasyAssets/img/backArrow.png'
import BettingIcon from './../../fantasyAssets/img/bettingIcon.png'
import { Link, useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
import { getFantasyAuth } from '../../fantasyServices'
import { fantasyAddUserData } from '../../../store/action'
import { useDispatch } from 'react-redux'
import { ROUTES_CONST } from '../../../constants/routeConstant'


const FantasyHeader = ({ gameHeader, teamViewHeader, StockList, isCustom, setIsCustom, title, time }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch()

  const getProfileData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`);
      if (res.data.success) {
        dispatch(fantasyAddUserData(res.data.data));
      }
    } catch (error) {
      console.log(error)
    }

  };

  useEffect(() => {
    getProfileData()
    //eslint-disable-next-line
  }, [])
  return (
    <>
      <navbar className="col-12 header d-flex align-items-center px-0">
        <div className="row mx-0 innerheader align-items-center w-100 justify-content-between">
          {gameHeader ? (
            <>
              <div className="col-auto gameDetailHeader">
                <div className="row mx-0 align-items-center">
                  <div className="col-auto px-0 backBtn">
                    <img src={BackBtnIcon}
                      onClick={() => navigate(-1)}
                      className='h-100 w-100 object-fit-contain d-flex' alt="" />
                  </div>
                  <div className="col">
                    <div className="gameTitle">{title}</div>
                    <div className="timeLeft">{time}</div>
                  </div>
                </div>
              </div>
              {StockList ? (
                <div className={`col-auto customAllocation ${isCustom ? 'active' : ''}`} onClick={() => setIsCustom(!isCustom)}>
                  Custom allocation
                  <div className="customAllocationBtn d-flex ms-2"></div>
                </div>
              ) : (
                <div className="col-auto notification">
                  <div className="row mx-0 gap-3 align-items-center">
                    {/* <div className="col-auto px-0">
                      <Link to={ROUTES_CONST.SPORTS} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                        <img src={BettingIcon} className="w-100" alt="bell" />
                      </Link>
                    </div> */}
                    <div className="col-auto px-0">
                      <Link to={ROUTES_CONST.FANTASY_WALLET} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                        <img src={WalletIcon} className="w-100" alt="bell" />
                      </Link>
                    </div>
                    <div className="col-auto px-0">
                      <Link to={ROUTES_CONST.FANTASY_NOTIFICATION} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                        <img src={NotificationIcon} className="w-100" alt="bell" />
                      </Link>
                    </div>
                  </div>
                </div>
              )
              }
            </>

          ) : (
            teamViewHeader ? (
              <>
                <div className="col-auto gameDetailHeader">
                  <div className="row mx-0 align-items-center">
                    <div className="col-auto px-0 backBtn">
                      <img src={BackBtnIcon}
                        onClick={() => navigate(-1)}
                        className='h-100 w-100 object-fit-contain d-flex' alt="" />
                    </div>
                    <div className="col">
                      <div className="gameTitle">jitr11829654</div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="col-auto">
                  <div
                    className="gamesLogo d-flex cursor-pointer justify-content-center"
                    onClick={() => navigate('/')}
                  >
                    <img src="assets/img/heroLogo.png" alt="logo" className="h-100" />
                  </div>
                </div>
                <div className="col-auto notification">
                  <div className="row mx-0 gap-3 align-items-center">
                    {/* <div className="col-auto px-0">
                        <Link to={ROUTES_CONST.SPORTS} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                          <img src={BettingIcon} className="w-100" alt="bell" />
                        </Link>
                      </div> */}
                    <div className="col-auto px-0">
                      <Link to={ROUTES_CONST.FANTASY_WALLET} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                        <img src={WalletIcon} className="w-100" alt="bell" />
                      </Link>
                    </div>
                    <div className="col-auto px-0">
                      <Link to={ROUTES_CONST.FANTASY_NOTIFICATION} type="button" className="notificationIcon shadow-none bg-transparent p-0 border-0">
                        <img src={NotificationIcon} className="w-100" alt="bell" />
                      </Link>
                    </div>
                  </div>
                </div>
              </>
            )
          )
          }
        </div>
      </navbar>
    </>
  )
}

export default FantasyHeader