import axios from 'axios'
import { ADMIN_FRONTEND_LOGGED_IN_ID, STOCK_USER_ID, FANTASY_TOKEN_NAME, fantasyBaseUrl } from '../../constants/index'

export const getFantasy = async (url, body) => {
    // try {
    const response = await axios.get(`${fantasyBaseUrl}/${url}`, body, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",

        }
    })
    return response
    // } catch (error) {
    // console.log("error=>", error)
    // return error
    // }

}

export const getFantasyAuth = async (url, tokenInit) => {
    const bURL = fantasyBaseUrl
    const token = localStorage.getItem(FANTASY_TOKEN_NAME)
    // console.log("token from getapiAuth", token)
    try {
        const response = await axios.get(`${bURL}/${url}`, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${tokenInit ? tokenInit : token}`,
            }
        })
        return response
    } catch (error) {

        if (error?.response?.data?.msg === "Invalid Token") {
            localStorage.removeItem(FANTASY_TOKEN_NAME)
            // localStorage.removeItem(FANTASY_TOKEN_NAME);
            // localStorage.removeItem(TRADING_TOKEN_NAME);
            localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID)
            localStorage.removeItem(STOCK_USER_ID)
            // window.location.reload(true);
        }
        throw new Error(error);
    }
}

// no auth token required
export const postFantasyAPI = async (url, params, admin) => {
    const bURL = fantasyBaseUrl
    // try {
    const response = await axios.post(`${bURL}/${url}`, params, {
        headers: {
            "Content-Type": "application/json; charset=utf-8",
            Accept: "application/json",
        }
    })
    return response
    // } catch (error) {
    // console.log("error=>", error)
    // return error

    // }
}

export const postFantasyAuth = async (url, params, admin) => {
    // const token = localStorage.getItem(FANTASY_TOKEN_NAME)
    const bURL = fantasyBaseUrl
    const token = localStorage.getItem(FANTASY_TOKEN_NAME)
    try {
        const response = await axios.post(`${bURL}/${url}`, params, {
            headers: {
                "Content-Type": "application/json; charset=utf-8",
                Accept: "application/json",
                Authorization: `Bearer ${token}`,
            }
        })
        return response
    } catch (error) {
        if (error?.response?.data?.msg === "Invalid Token") {
            localStorage.removeItem(FANTASY_TOKEN_NAME)
            // localStorage.removeItem(FANTASY_TOKEN_NAME);
            // localStorage.removeItem(TRADING_TOKEN_NAME);
            localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID)
            localStorage.removeItem(STOCK_USER_ID)
            // window.location.reload(true);
        }
        throw new Error(error);
        // console.log("error=>", error)
        // return error
    }
}

export const postFantasyAuthFormData = async (url, params) => {
    try {
        const token = localStorage.getItem(FANTASY_TOKEN_NAME)
        const response = await axios({
            method: "post",
            url: `${fantasyBaseUrl}/${url}`,
            data: params,
            headers: { "Content-Type": "multipart/form-data", Authorization: `Bearer ${token}`, },
        })
        return response

    } catch (error) {
        if (error?.response?.data?.msg === "Invalid Token") {
            localStorage.removeItem(FANTASY_TOKEN_NAME)
            // localStorage.removeItem(FANTASY_TOKEN_NAME);
            // localStorage.removeItem(TRADING_TOKEN_NAME);
            localStorage.removeItem(ADMIN_FRONTEND_LOGGED_IN_ID)
            localStorage.removeItem(STOCK_USER_ID)
            // window.location.reload(true);
        }
        throw new Error(error);
    }
}
export const deleteFantasyAuth = async (url) => {
    const token = localStorage.getItem(FANTASY_TOKEN_NAME);
    try {
        const response = await axios.delete(`${fantasyBaseUrl}/${url}`, {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        });
        return response;
    } catch (error) {
        // console.log("error=>", error);
        return error;
    }
};
