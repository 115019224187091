import React, { useEffect ,useState} from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import plusIcon from './../../fantasyAssets/img/plus.png'
import playerIcon from './../../fantasyAssets/img/ban.png'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { TeamConstants } from '../../../constants/createTeamConstants'
import { useDispatch, useSelector } from 'react-redux'
import { getFantasyAuth, postFantasyAuth } from '../../fantasyServices'
import { addTeamList, setCapAndViceCap } from '../../../store/action'
import { getUsableBalance } from '../../fantasyComponents/commonFunctions'
import ConfirmationModal from '../../fantasyComponents/confirmationModal/ConfirmationModal'
import { errorToaster, succesToaster } from '../../../utils/toaster'

const SelectCaptain = () => {
  const [Captian, SetCaptain] = useState([]);
  const dispatch = useDispatch()
  const [modalVisible, setModalVisible] = useState(false);
  const [modalBalance, setModalBalance] = useState();
  const [teamSel, setteamSel] = useState();
  const [viceCaptian, SetViceCaptain] = useState([]);
  const navigate = useNavigate()
  const { state } = useLocation()
  const myTeam = useSelector(state => state?.myTeamData)
  const encodedSN = encodeURIComponent(state?.teamData.name)
  const capAndViceCap = useSelector(state => state?.capAndViceCap)

  /// cap and vice cap
  useEffect(() => {
    if (!!state?.data.filter(el => el.name == state?.capVice.captain)?.length && !!state?.data.filter(el => el.name == state?.capVice.viceCaptain).length) {
      SetCaptain(state?.data.filter(el => el.name == state?.capVice.captain));
      SetViceCaptain(state?.data.filter(el => el.name == state?.capVice.viceCaptain));
    } else if (capAndViceCap.captain && capAndViceCap?.viceCaptian) {
      SetCaptain(capAndViceCap?.captain)
      SetViceCaptain(capAndViceCap?.viceCaptian)
    }
  }, []);
  const SavePlayers = async () => {
    // setloading(true);
    let playerSel = '';
    state?.data.map((el, index) => {
      if (index != state?.data.length - 1) {
        playerSel = playerSel + el.playerid + ',';
      } else {
        playerSel = playerSel + el.playerid;
      }
    });

    let params = {
      matchkey: !!state?.teamData?.id ? state?.teamData?.id : state?.teamData?.matchkey,
      teamnumber: `${!!state?.edit ? state?.edit : myTeam.length + 1}`,
      players: playerSel,
      captain: Captian[0].playerid,
      vicecaptain: viceCaptian[0]?.playerid,
    };
    // console.log('params', params);
    let res = await postFantasyAuth('/createmyteam', params);
    setteamSel(res)
    if (!!state?.challenge) {
      getBalnce()
    } else {
      if (!!state?.teamData?.id) {
        getJoinedTeamData()
        succesToaster("Team created successfully!")
        navigate(`${ROUTES_CONST.FANTASY_HOME}${state?.teamData?.id}?contest_name=${encodedSN}`, { state: state?.teamData });
      } else {
        errorToaster("error creating team!")
        // navigation.navigation.navigate('LiveMatchContest', {
        //   data: teamData,
        // });
      }
    }
  };

  // Function for managing join contest
  const getBalnce = async () => {
    let data = await getUsableBalance(state?.challenge)
    setModalBalance(data);
    setModalVisible(true);
  };

  const getJoinedTeamData = async () => {
    try {
      const res = await getFantasyAuth(`/getMyTeams?matchkey=${state?.teamData?.id}`);
      if (res?.data?.success) {
        dispatch(addTeamList(res?.data?.data))
      }
    } catch (error) {
      console.log("error", error)
    }
  };


  return (
    <>
      <div className="row fantasyInner">
        <FantasyHeader gameHeader={true} title={'Create Team'} />
        <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
          <div className="row createTeam mx-0">
            <div className="col-12 px-0">
              <div className="tableSectionMain">
                <table className="table tableSection  align-middle table-hover">
                  <thead>
                    <tr>
                      <th scope="col">Type</th>
                      <th scope="col">Points</th>
                      <th scope="col" className='text-end'>% c By</th>
                      <th scope="col" className='text-end'>% vc By</th>
                    </tr>
                  </thead>
                  <tbody>
                    {state?.data?.map((item) => (
                      <tr className=''>
                        <td className='onlyUserImg'>
                          <div className="row align-items-center">
                            <div className="col-auto">
                              <div className="userSection position-relative">
                                <div className="userImg">
                                  <img src={item?.image} alt="bsk" className="img-fluid w-100 h-100" />
                                </div>
                                <div className="teamName">{item.role == TeamConstants.WK ? 'WK' : item.role == TeamConstants.AR ? "AR" : item.role == TeamConstants.BOWL ? "BOWL" : item.role == TeamConstants.BAT ? "BAT" : ''}</div>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="row mx-0 align-items-center">
                            <div className="col ps-0">
                              <div className="userName">{item?.name}</div>
                              <div className="userParcent">Sel By: {item?.player_selection_percentage}%</div>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div className="row mx-0 selectCapRow">
                            <div className="col-12 px-0 d-flex justify-content-center">
                              <button onClick={() => {
                                if (Captian[0] == item) {
                                  errorToaster("Player is already captain ")
                                  return
                                }
                                if (viceCaptian[0] == item) {
                                  SetCaptain([item]);
                                  SetViceCaptain([]);
                                  dispatch(setCapAndViceCap({
                                    captain: [item],
                                    viceCaptian: []
                                  }))
                                  succesToaster("Captain updated successfully")
                                } else {
                                  SetCaptain([item]);
                                  dispatch(setCapAndViceCap({
                                    captain: [item],
                                    viceCaptian: viceCaptian
                                  }))
                                  succesToaster("Captain updated successfully")

                                }
                              }} className={`selectCapBtn viceCaptainBtn ${Captian[0]?.p_id === item.p_id ? "active" : ''}`}>c</button>
                            </div>
                            <div className="col-12 px-0 capVal text-center">{item?.captain_selection_percentage}%</div>
                          </div>
                        </td>
                        <td>
                          <div className="row mx-0 selectCapRow">
                            <div className="col-12 px-0 d-flex justify-content-center">
                              <button onClick={() => {
                                if (viceCaptian[0] == item) {
                                  errorToaster("Player is already vice captain ")
                                  return
                                }
                                if (Captian[0] == item) {
                                  SetViceCaptain([item]);
                                  SetCaptain([]);
                                  dispatch(setCapAndViceCap({
                                    captain: [],
                                    viceCaptian: [item]
                                  }))
                                  succesToaster("Vice captain updated successfully")

                                } else {
                                  SetViceCaptain([item]);
                                  dispatch(setCapAndViceCap({
                                    captain: Captian,
                                    viceCaptian: [item]
                                  }))
                                  succesToaster("Vice captain updated successfully")

                                }
                              }} className={`selectCapBtn viceCaptainBtn ${viceCaptian[0]?.p_id === item.p_id ? 'active' : ''}`}>vc</button>
                            </div>
                            <div className="col-12 px-0 capVal text-center">{item?.vice_captain_selection_percentage}%</div>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
      <div className="fixedBtns">
        <button
          className='createTeamBtn2'
          onClick={() => navigate(ROUTES_CONST.VIEW_TEAM, {
            state: {
              players: state?.data,
              myteam: false,
              data: state?.teamData,
            }
          }
          )
          }
        >
          Team Preview
        </button>
        <button
          onClick={() => {
            if (!(viceCaptian?.length && Captian?.length)) {
              errorToaster("Please Select captain and vice captain")
              return
            }
            SavePlayers()
          }}
          // disabled={!(viceCaptian?.length && Captian?.length)}
          className={`createTeamBtn2 ${viceCaptian?.length && Captian?.length ? "active" : ''}`}>
          Continue
        </button>
      </div>

      <ConfirmationModal
        setShow={setModalVisible}
        show={modalVisible}
        modalBalance={modalBalance}
        selectedPlayers={state?.data}
        data={state?.teamData}
        selectedContest={state?.challenge}
        jointeamid={teamSel}
      />
    </>
  )
}

export default SelectCaptain