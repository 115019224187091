import React, { useEffect, useState } from 'react'
import filterIcon from './../../../fantasyAssets/img/filterIcon.png'
import addIcon from './../../../fantasyAssets/img/addIcon.png'
import upArrowIcon from './../../../fantasyAssets/img/upArrow.png'
import downArrowIcon from './../../../fantasyAssets/img/downArrow.png'
import ContestCard from '../../../fantasyComponents/contestCard/ContestCard'
import FilterOffcanvas from '../../../fantasyComponents/filterOffcanvas/FilterOffcanvas'
import FilteredDataOffcanvas from '../../../fantasyComponents/FilteredDataOffcanvas'
import { getUsableBalance, selectingTeam } from '../../../fantasyComponents/commonFunctions'
import { useNavigate } from 'react-router-dom'
import ConfirmationModal from '../../../fantasyComponents/confirmationModal/ConfirmationModal'
import { ROUTES_CONST } from '../../../../constants/routeConstant'
import { useDispatch } from 'react-redux'
import { playerConditionsAction, selectTeamsAction } from '../../../../store/action'
import { Spinner } from 'react-bootstrap'

const Contest = ({
  contests,
  match,
  getContests,
  upcomingLoader
}) => {
  const navigate = useNavigate()
  const [showFilter, setShowFilter] = useState(false)
  const [showFilterData, setShowFilterData] = useState(false)
  const [selectedContest, setselectedContest] = useState('');
  const [modalBalance, setModalBalance] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [contestData, setContestData] = useState(contests)
  const [selectedPlayers, setselectedPlayers] = useState([]);
  const [addedFilter, setAddedFilters] = useState([])
  const dispatch = useDispatch()

  const [activeSort, setActiveSort] = useState({
    entry: {
      index: "entry",
      type: ""
    },
    spots: {
      index: "spots",
      type: ""
    },
    prizePool: {
      index: "prizePool",
      type: ""
    },
    winner: {
      index: "winner",
      type: ""
    }
  })

  const handleFilter = () => {
    setShowFilter(true)
    document.body.classList.add('offcanvasOpen')
  }

  const handleSorting = (sort) => {
    const obj = {
      entry: {
        index: "entry",
        type: ""
      },
      spots: {
        index: "spots",
        type: ""
      },
      prizePool: {
        index: "prizePool",
        type: ""
      },
      winner: {
        index: "winner",
        type: ""
      }
    }
    if (sort.type === '') {
      obj[sort.index] = {
        index: sort.index,
        type: 'ascending'
      }
      setActiveSort(obj)
    } else if (sort.type === 'ascending') {
      obj[sort.index] = {
        index: sort.index,
        type: 'descending'
      }
      setActiveSort(obj)
    } else if (sort.type === 'descending') {
      obj[sort.index] = {
        index: sort.index,
        type: ''
      }
      setActiveSort(obj)
    }
  }
  // console.log("match------>>>>>>", { match })
  const sortSpotsHandler = (e) => {
    const data = structuredClone(contests)
    if (e.target.classList.contains('ascending')) {
      const sorted = data.map((item, index) => {
        item?.contest?.sort((a, b) => (a.maximum_user - a.joinedusers) - (b.maximum_user - b.joinedusers))
        return item
      })
      setContestData(sorted)
    } else if (e.target.classList.contains('descending')) {
      const sorted = data.map((item, index) => {
        item?.contest?.sort((a, b) => (b.maximum_user - b.joinedusers) - (a.maximum_user - a.joinedusers))
        return item
      })
      setContestData(sorted)
    }
  }

  const sortEntryHandler = (e, property) => {
    if (!property) return
    const data = structuredClone(contests)
    if (e.target.classList.contains('ascending')) {
      const sorted = data.map((item) => {
        item?.contest?.sort((a, b) => a[property] - b[property])
        return item
      })
      setContestData(sorted)
    } else if (e.target.classList.contains('descending')) {
      const sorted = data.map((item) => {
        item?.contest?.sort((a, b) => b[property] - a[property])
        return item
      })
      setContestData(sorted)
    }
  }

  // Function for team Selecting
  const TeamSelection = (elem) => {
    selectingTeam(navigate, match, elem, contests, setSelectionData)
    getContests()
  }
  useEffect(() => {
    dispatch(selectTeamsAction([]))
    dispatch(playerConditionsAction([]))
  }, [])

  // Function for updating state data
  const setSelectionData = async (players, contest) => {
    setselectedPlayers(players);
    setselectedContest(contest);
    let balanceData = await getUsableBalance(contest);
    setModalBalance(balanceData)
    setModalVisible(true)
  }

console.log({contestData})
  useEffect(() => {
    setContestData(contests)
  }, [contests])
  // console.log("contestData", contestData)
  return (
    <>
      <div className="row filterRow align-items-center flex-nowrap mt-1">
        <div className="col-auto sortBy">
          Sort By :
        </div>
        <div className="col sortOptionCol overflow-x-auto">
          <div className="row sortOptionRow flex-nowrap">
            <div
              className={`col-auto text-uppercase sortOpt ${activeSort.entry.type}`}
              onClick={(e) => {
                handleSorting(activeSort.entry)
                sortEntryHandler(e, "entryfee")
              }}
              >
              Entry
              <img src={upArrowIcon} className='upArrow' alt="" />
              <img src={downArrowIcon} className='downArrow' alt="" />
            </div>
            <div
              className={`col-auto text-uppercase sortOpt  ${activeSort.spots.type}`}
              onClick={(e) => {
                // handleSorting(e)
                handleSorting(activeSort.spots)
                sortSpotsHandler(e)
              }}
            >
              Spots
              <img src={upArrowIcon} className='upArrow' alt="" />
              <img src={downArrowIcon} className='downArrow' alt="" />
            </div>
            <div
              className={`col-auto text-uppercase sortOpt ${activeSort.prizePool.type}`}
              onClick={(e) => {
                handleSorting(activeSort.prizePool)
                sortEntryHandler(e, "win_amount")
              }}
            >
              Prize Pool
              <img src={upArrowIcon} className='upArrow' alt="" />
              <img src={downArrowIcon} className='downArrow' alt="" />
            </div>
            {/* <div className={`col-auto text-uppercase sortOpt ${activeSort.winner.type}`}
              onClick={(e) => {
                handleSorting(activeSort.winner)
                handleSorting(e)
              }}>
              Winner
              <img src={upArrowIcon} className='upArrow' alt="" />
              <img src={downArrowIcon} className='downArrow' alt="" />
            </div> */}
          </div>
        </div>
        <div className="col-auto filterBtnCol px-0">
          <button className='shadow-none border-0 d-flex align-items-center filterBtn'
            onClick={handleFilter}>
            <img src={filterIcon} alt="" />
          </button>
        </div>
      </div>
      {/* 
      <div className="row mx-0 mt-1">
        <div className="col-12 d-flex align-items-center justify-content-between privateLeagueCol">
          <div className='privateLeagueTxt'>Create a private league</div>
          <button className="shadow-none border-0 d-flex align-items-center privateLeagueBtn">
            <img src={addIcon} alt="" />
            create new
          </button>
        </div>
      </div> */}
      {
        upcomingLoader ? (
          <div className='w-100 h-100 d-flex justify-content-center'>
            <Spinner color='white' size='sm' />
          </div>
        ) : (
          contestData.length === 0 ? (
            <div className='w-100 h-100 d-flex justify-content-center text-light' >
              No Data Available
            </div>
          ) : (
            contestData?.map((item) => (
              <div
                key={item?.catid}
                className="row"
              >
                <div className="col-12 contestHeading">
                  <div className="title">
                    {item?.catname}
                  </div>
                  <div className="subtitle">
                    {item?.sub_title}
                  </div>
                </div>
                {
                  !item?.contest || item?.contest === 0 ? (
                    <div className='w-100 h-100 d-flex justify-content-center text-light' >
                      No Data Available
                    </div>
                  ) : (
                    item?.contest?.map((elem) => (
                      <ContestCard
                        practice={item?.name?.toLowerCase().includes("practice")}
                        win_amount={elem?.win_amount}
                        entryfee={elem?.entryfee}
                        isselected={elem?.isselected}
                        maximum_user={elem?.maximum_user}
                        joinedusers={elem?.joinedusers}
                        winning_percentage={elem?.winning_percentage}
                        multi_entry={elem?.multi_entry}
                        confirmed_challenge={elem?.confirmed_challenge}
                        handleJoin={(e) => {
                          e.stopPropagation();
                          TeamSelection(elem);
                        }}
                        handleLeader={(e) => {
                          e.stopPropagation();
                          navigate(ROUTES_CONST.FANTASY_LEADERbOARD, {
                            state: {
                              data: match,
                              el: elem,
                              contest: elem,
                              // selectingTeam: TeamSelection,
                              live: true,
                              // type: type,
                            }
                          })
                        }}
                      />
                    ))
                  )
                }
              </div>
            ))
          )
        )
      }
      {/* <div className="row">
        <div className="col-12 contestHeading">
          <div className="title">Grand Leaderboard Contest</div>
          <div className="subtitle">Win Exciting Pricing</div>
        </div>
        <ContestCard />
        <ContestCard />
      </div>
      <div className="row">
        <div className="col-12 contestHeading">
          <div className="title">Practice Contest</div>
          <div className="subtitle">Hone Your Skills</div>
        </div>
        <ContestCard practice={true} />
        <ContestCard practice={true} />
      </div> */}
      <FilterOffcanvas
        showFilter={showFilter}
        setShowFilter={setShowFilter}
        contestData={contests}
        setContestData={setContestData}
        showFilterData={showFilterData}
        setShowFilterData={setShowFilterData}
        addedFilter={addedFilter}
        setAddedFilters={setAddedFilters}
      />
      <FilteredDataOffcanvas
        isOpen={showFilterData}
        setIsOpen={setShowFilterData}
        setShowFilter={setShowFilter}
        addedFilter={addedFilter}
        setAddedFilters={setAddedFilters}
        contestData={contests}
        TeamSelection={TeamSelection}
        match={match}

      />
      <ConfirmationModal
        setShow={setModalVisible}
        show={modalVisible}
        modalBalance={modalBalance}
        // setContestLoader={setContestLoader}
        // ContestLoader={contestLoader}
        selectedPlayers={selectedPlayers}
        data={match}
        selectedContest={selectedContest}
        getContests={getContests}
      />
    </>
  )
}

export default Contest