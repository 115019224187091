import { Formik } from 'formik'
import React from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import * as Yup from "yup";
import ErrorMessage from '../../../components/common/ErrorMessage/ErrorMessage';
import { postFantasyAPI } from '../../fantasyServices';
import { FANTASY_TOKEN_NAME } from '../../../constants';
import { useAuth } from '../../../hooks/useAuth';
import { errorToaster } from '../../../utils/toaster';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';

const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    email: Yup.string()
        .required("This field is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
    password: Yup.string().required("This field is required"),
});
const Login = () => {
    const navigate = useNavigate()
    const { token, setToken } = useAuth()

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            const userData = jwtDecode(credentialResponse?.credential)
            console.log(userData)
            const payload = {
                email: userData?.email,
                name: userData?.name,
                image: userData?.picture
            }
            const res = await postFantasyAPI('socialauthentication', payload)
            console.log({ res })
            if (res.data.success) {
                navigate(ROUTES_CONST.FANTASY_HOME)
                setToken(res.data?.data?.token)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {
            console.log(error)
        }
    }
    const handleSubmit = async (values) => {
        try {
            const payload = {
                email: values.email,
                password: values.password
            }
            const res = await postFantasyAPI('login', payload)
            if (res.data.success) {
                setToken(res.data.data?.token)
                // navigate(ROUTES_CONST.FANTASY_HOME)
                // localStorage.setItem(FANTASY_TOKEN_NAME, res.data.data?.token)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {

        }
    }
    return (
        <div className="row fantasyInner">
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className='d-flex justify-content-center text-light pt-4'>
                    <div>
                        Login
                    </div>
                </div>
                <Formik
                    initialValues={{
                        // name: "",
                        email: "",
                        password: "",
                        phone: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {
                        ({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                        }) => (
                            <form >
                                <div className="row mx-0 py-3 depositForm gap-4 ">
                                    <div className="col-12">
                                        <label htmlFor="addcase11" className="form-label labelHeading addText">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase11"
                                            placeholder="Your Email"
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.email &&
                                                touched.email &&
                                                errors.email
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="addcase123" className="form-label labelHeading addText">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase123"
                                            placeholder="Your Password"
                                            name='password'
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.password &&
                                                touched.password &&
                                                errors.password
                                            }
                                        </ErrorMessage>
                                        <div className='form-label labelHeading addText cursor-pointer'
                                            onClick={() => navigate(ROUTES_CONST.FANTASY_FORGOT_PASSWORD)}
                                        >Forgot password ?</div>
                                    </div>
                                    <div className="col-12">
                                        <button
                                            type="button"
                                            className=" btnStyle2"
                                            onClick={handleSubmit}
                                        >Login</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }

                </Formik>
                <div
                    className='d-flex justify-content-center'
                >
                    <GoogleLogin
                        onSuccess={handleGoogleLogin}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />;
                </div>
                <div className='text-light m-2'>
                    Don't have an account?
                    <NavLink
                        className={'text-light mx-1'}
                        to={ROUTES_CONST.FANTASY_REGISTER}
                    >
                        Register
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Login