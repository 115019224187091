import React from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import terms from './../../fantasyAssets/img/terms & condition.svg'
import about from './../../fantasyAssets/img/about us.svg'
import rytArrow from './../../fantasyAssets/img/rytChewan.png'
import contactUs from './../../fantasyAssets/img/contact us.svg'
import legality from './../../fantasyAssets/img/legality.svg'
import privacy from './../../fantasyAssets/img/privacy.svg'
import friends from './../../fantasyAssets/img/invitefriends (3).svg'
import partner from './../../fantasyAssets/img/partner.svg'
import ConfirmationModal from '../../fantasyComponents/confirmationModal/ConfirmationModal'
import { Link } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'

const More = () => {
    return(
        <>
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'More'}/>
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row mainCenterPart g-4 mx-0 py-4">
                <div className="col-12">
                    <div className="warningCard addcase card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={friends} className="w-100" alt=""/></div></div>
                                <div className="col"        >
                                    <Link to={ROUTES_CONST.FANTASY_INVITE_FRIEND} className="balanceAmount balanceAmountTxt stretched-link ">Invite Friends</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12"> 
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0 "><div className="warningImg d-flex align-items-center justify-content-center rounded-circle"><img src={partner} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to="" className="balanceAmount balanceAmountTxt stretched-link "> Partner Program </Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt=""/></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={contactUs} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to={ROUTES_CONST.FANTASY_CONTACT_HELP} className="balanceAmount balanceAmountTxt stretched-link">Contact Us</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={terms} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to={ROUTES_CONST.FANTASY_TERM_AND_CONDITION} className="balanceAmount balanceAmountTxt stretched-link">Terms & Condition</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={about} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to="" className="balanceAmount balanceAmountTxt stretched-link">About Us</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={legality} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to="" className="balanceAmount balanceAmountTxt stretched-link">Legality</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-12">
                    <div className="warningCard card border-0 rounded-4 position-relative">
                        <div className="card-body">
                            <div className="row mx-0 align-items-center">
                                <div className="col-auto ps-0"><div className="warningImg rounded-circle"><img src={privacy} className="w-100" alt=""/></div></div>
                                <div className="col">
                                    <Link to="" className="balanceAmount balanceAmountTxt stretched-link">Privacy & Policy</Link>
                                </div>
                                <div className='col-auto'><img className='rytArrow' src={rytArrow} alt="" /></div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
                <ConfirmationModal/>
                </div>  
            <FantasyBottomBar/>
            </div>
        </>
    )
}

export default More