import React, { useEffect, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import bannerImg from './../../fantasyAssets/img/promotionBanner.svg'
import { getFantasyAuth } from '../../fantasyServices';
import { Spinner } from 'react-bootstrap';

const Banner = () => {
  const [banner, setBanner] = useState([])
  const [isLoading, setIsLoading] = useState(true)

  const getBanner = async () => {
    try {
      let response = await getFantasyAuth('/getmainbanner?fantasy_type=Cricket');
      console.log({ response }, 'resposne')
      if (response.data) {

        setBanner(response?.data?.data);
      }

    } catch (error) {

    } finally {
      setIsLoading(false);
    }

  };
  useEffect(() => {
    getBanner()
  }, [])

  return (
    <>
      <div className="col-12 py-3 swiperBanner">
        <Swiper
          pagination={{ el: ".bannerPagination", }}
          modules={[Pagination]}
          slidesPerView={1}
          spaceBetween={10}
          loop={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          centeredSlides={true}>
          {
            isLoading ? (
              <div className='w-100 h-100 d-flex justify-content-center'>
                <Spinner color='white' size='sm' />
              </div>
            ) : (
              banner.length === 0 ? (
                <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
              ) : (
                // banner?.map((item) => (
                  <>
                    <SwiperSlide>
                      <div className="rounded-2 overflow-hidden bannerImgWrapper">
                        <img src={bannerImg} className="w-100 h-100 object-fit-cover" alt="" />
                        {/* <img src={item?.image} className="w-100 h-100 object-fit-cover" alt="" /> */}
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="rounded-2 overflow-hidden bannerImgWrapper">
                        <img src={bannerImg} className="w-100 h-100 object-fit-cover" alt="" />
                      </div>
                    </SwiperSlide>
                    <SwiperSlide>
                      <div className="rounded-2 overflow-hidden bannerImgWrapper">
                        <img src={bannerImg} className="w-100 h-100 object-fit-cover" alt="" />
                      </div>
                    </SwiperSlide>
                  </>

                //  )) 
              )
            )
          }


        </Swiper>
        <div className="bannerPagination"></div>
      </div>
    </>
  )
}

export default Banner