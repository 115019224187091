import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { ROUTES_CONST } from "../constants/routeConstant";
import { Spinner } from "react-bootstrap";
import FantasyHome from "../fantasy/fantasyPages/home";
import FantasyGameDetail from "../fantasy/fantasyPages/gameDetailPage";
import CreateTeam from "../fantasy/fantasyPages/createTeam/CreateTeam";
import TeamView from "../fantasy/fantasyPages/teamView/TeamView";
import SelectCaptain from "../fantasy/fantasyPages/selectCaptain/SelectCapain";
import MyMatchesPage from "../fantasy/fantasyPages/myMatchesPage";
import FantasyWallet from "../fantasy/fantasyPages/fantasyWallet/FantasyWallet";
import DepositCash from "../fantasy/fantasyPages/depositCash/DepositCash";
import Transaction from "../fantasy/fantasyPages/transaction/Transaction";
import LeaderBoard from "../fantasy/fantasyPages/leaderBoard/LeaderBoard";
import InviteFriend from "../fantasy/fantasyPages/inviteFriend/InviteFriend";
import VerifyAccount from "../fantasy/fantasyPages/verifyAccount/VerifyAccount";
import Profile from "../fantasy/fantasyPages/profilePage/Profile";
import PlayInsights from "../fantasy/fantasyPages/profilePage/PlayInsights";
import More from "../fantasy/fantasyPages/more/More";
import MyMatchesContest from "../fantasy/fantasyPages/myMatchContest";
import ContactHelp from "../fantasy/fantasyPages/contact&Help/ContactHelp";
import TermsAndCondition from "../fantasy/fantasyPages/term&Condition/TermsAndCondition";
import MyPortfolio from "../fantasy/fantasyPages/myPortfolio/MyPortfolio";
import Notification from "../fantasy/fantasyPages/notification/Notification";
import MyMatchesDetail from "../fantasy/fantasyPages/myMatchesDetail";
import MyTeamsPage from "../fantasy/fantasyPages/myTeamsPage";
import ContestDetailsPage from "../fantasy/fantasyPages/contestDetails/ContestDetailsPage";
import StockUsableBalance from "../fantasy/fantasyPages/stockUsableBalance/StockUsableBalance";
import SelectStocks from "../fantasy/fantasyPages/SelectStocks";
import StockList from "../fantasy/fantasyPages/stockList/StockList";
import StockLeaderBoard from "../fantasy/fantasyPages/stockLeaderBoard/StockLeaderBoard";
import FantasyProtectedRoute from "./protected/FantasyProtectedRoute";
import Register from "../fantasy/fantasyPages/Register";
import Login from "../fantasy/fantasyPages/Login";
import RegisterOtp from "../fantasy/fantasyPages/Register-otp";
import ForgotPassword from "../fantasy/fantasyPages/ForgotPassword";
import ForgotOtp from "../fantasy/fantasyPages/forgot-otp";
import ChangePassword from "../fantasy/fantasyPages/changePassword";

const RoutesComp = () => {

  return (
    <>
      <Routes
        fallbackElement={<Spinner color="red" />}
      >
        {/* FANTASY ROUTES */}
        <Route path={ROUTES_CONST.FANTASY_HOME} element={<FantasyProtectedRoute Component={FantasyHome} />} />
        <Route path={`${ROUTES_CONST.FANTASY_HOME}/:id`} element={<FantasyProtectedRoute Component={FantasyGameDetail} />} />
        <Route path={`${ROUTES_CONST.FANTASY_SELECT_STOCKS_PAGE}`} element={<Navigate to={ROUTES_CONST.FANTASY_HOME} />} />
        <Route path={`${ROUTES_CONST.FANTASY_SELECT_STOCKS_PAGE}/:stockId`} element={<FantasyProtectedRoute Component={SelectStocks} />} />
        <Route path={ROUTES_CONST.CREATE_TEAM} element={<Navigate to={ROUTES_CONST.FANTASY_HOME} />} />
        <Route path={`${ROUTES_CONST.CREATE_TEAM}/:matchKey`} element={<FantasyProtectedRoute Component={CreateTeam} />} />
        <Route path={ROUTES_CONST.VIEW_TEAM} element={<FantasyProtectedRoute Component={TeamView} />} />
        <Route path={ROUTES_CONST.SELECT_CAPTAIN_VICECAPTAIN} element={<FantasyProtectedRoute Component={SelectCaptain} />} />
        <Route path={ROUTES_CONST.MY_MATCHES} element={<FantasyProtectedRoute Component={MyMatchesPage} />} />
        <Route path={ROUTES_CONST.MY_MATCHES_CONTEST} element={<FantasyProtectedRoute Component={MyMatchesContest} />} />
        <Route path={ROUTES_CONST.CONTEST_DETAILS_PAGE} element={<FantasyProtectedRoute Component={ContestDetailsPage} />} />

        <Route path={ROUTES_CONST.FANTASY_WALLET} element={<FantasyProtectedRoute Component={FantasyWallet} />} />
        <Route path={ROUTES_CONST.FANTASY_DEPOSIT_CASH} element={<FantasyProtectedRoute Component={DepositCash} />} />
        <Route path={ROUTES_CONST.FANTASY_TRANSACTION} element={<FantasyProtectedRoute Component={Transaction} />} />
        <Route path={ROUTES_CONST.FANTASY_LEADERbOARD} element={<FantasyProtectedRoute Component={LeaderBoard} />} />
        <Route path={ROUTES_CONST.FANTASY_INVITE_FRIEND} element={<FantasyProtectedRoute Component={InviteFriend} />} />
        <Route path={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT} element={<FantasyProtectedRoute Component={VerifyAccount} />} />
        <Route path={ROUTES_CONST.FANTASY_PROFILE} element={<FantasyProtectedRoute Component={Profile} />} />
        <Route path={ROUTES_CONST.FANTASY_PLAY_INSIGHT_PROFILE} element={<FantasyProtectedRoute Component={PlayInsights} />} />
        <Route path={ROUTES_CONST.FANTASY_MORE} element={<FantasyProtectedRoute Component={More} />} />
        <Route path={ROUTES_CONST.FANTASY_CONTACT_HELP} element={<FantasyProtectedRoute Component={ContactHelp} />} />
        <Route path={ROUTES_CONST.FANTASY_TERM_AND_CONDITION} element={<FantasyProtectedRoute Component={TermsAndCondition} />} />
        <Route path={ROUTES_CONST.FANTASY_MATCH_DETAIL} element={<FantasyProtectedRoute Component={MyMatchesDetail} />} />
        <Route path={ROUTES_CONST.MY_TEAMS_PAGE} element={<FantasyProtectedRoute Component={MyTeamsPage} />} />

        <Route path={ROUTES_CONST.FANTASY_WALLET} element={<FantasyProtectedRoute Component={FantasyWallet} />} />
        <Route path={ROUTES_CONST.FANTASY_DEPOSIT_CASH} element={<FantasyProtectedRoute Component={DepositCash} />} />
        <Route path={ROUTES_CONST.FANTASY_TRANSACTION} element={<FantasyProtectedRoute Component={Transaction} />} />
        <Route path={ROUTES_CONST.FANTASY_INVITE_FRIEND} element={<FantasyProtectedRoute Component={InviteFriend} />} />
        <Route path={ROUTES_CONST.FANTASY_VERIFY_ACCOUNT} element={<FantasyProtectedRoute Component={VerifyAccount} />} />
        <Route path={ROUTES_CONST.FANTASY_PROFILE} element={<FantasyProtectedRoute Component={Profile} />} />
        <Route path={ROUTES_CONST.FANTASY_PLAY_INSIGHT_PROFILE} element={<FantasyProtectedRoute Component={PlayInsights} />} />
        <Route path={ROUTES_CONST.FANTASY_MORE} element={<FantasyProtectedRoute Component={More} />} />
        <Route path={ROUTES_CONST.FANTASY_CONTACT_HELP} element={<FantasyProtectedRoute Component={ContactHelp} />} />
        <Route path={ROUTES_CONST.FANTASY_TERM_AND_CONDITION} element={<FantasyProtectedRoute Component={TermsAndCondition} />} />
        <Route path={ROUTES_CONST.FANTASY_MY_PORTFOLIO} element={<FantasyProtectedRoute Component={MyPortfolio} />} />
        <Route path={ROUTES_CONST.FANTASY_NOTIFICATION} element={<FantasyProtectedRoute Component={Notification} />} />
        <Route path={ROUTES_CONST.FANTASY_STOCK_USABLE_BALANCE} element={<FantasyProtectedRoute Component={StockUsableBalance} />} />
        <Route path={ROUTES_CONST.FANTASY_STOCK_LIST} element={<FantasyProtectedRoute Component={StockList} />} />
        <Route path={ROUTES_CONST.FANTASY_STOCK_LEADERBOARD} element={<FantasyProtectedRoute Component={StockLeaderBoard} />} />

        <Route
          path={ROUTES_CONST.FANTASY_REGISTER}
          element={<FantasyProtectedRoute Component={Register} />}
        />

        <Route
          path={ROUTES_CONST.FANTASY_REGISTER_OTP}
          element={<FantasyProtectedRoute Component={RegisterOtp} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_LOGIN}
          element={<FantasyProtectedRoute Component={Login} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_FORGOT_PASSWORD}
          element={<FantasyProtectedRoute Component={ForgotPassword} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_FORGOT_OTP}
          element={<FantasyProtectedRoute Component={ForgotOtp} />}
        />
        <Route
          path={ROUTES_CONST.FANTASY_CHANGE_PASSWORD}
          element={<FantasyProtectedRoute Component={ChangePassword} />}
        />
      </Routes>
    </>
  );
};

export default RoutesComp;
