import React, { useEffect, useState } from "react";
import panCard from "./../../fantasyAssets/img/panCard.png";
import panCardPic from "./../../fantasyAssets/img/panCardd.png";
import id from "./../../fantasyAssets/img/idCard.png";
import { getFantasyAuth, postFantasyAuthFormData } from "../../fantasyServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { fantasyAddUserData } from "../../../store/action";
import { useDispatch } from "react-redux";

const regex = {
  name: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
  pancard: /^([A-Z]{5})(\d{4})([A-Z])$/,
  dob: /^\d{4}-\d{2}-\d{2}$/,
};

const PanCard = ({ userData }) => {
  const [formValues, setFormValues] = useState({
    name: "",
    panCardNumber: "",
    dob: "",
  });
  const [formOnFocus, setFormOnFocus] = useState({
    name: false,
    panCardNumber: false,
    dob: false,
  });
  const [panImage, setPanImage] = useState(id);
  const [url, setUrl] = useState("");
  const [panDetails, setPanDetails] = useState({});
  const [formErrors, setFormErrors] = useState({
    name: "",
    panCardNumber: "",
    dob: "",
  });
  const dispatch = useDispatch();
  const currentDate = new Date();
  const year = currentDate.getFullYear() - 18;
  const month = currentDate.getMonth();
  const day = currentDate.getDate();
  const date18YearsBefore = new Date(year, month, day);
  const disableYear = new Date(date18YearsBefore).toISOString().split("T")[0];

  const getPanDetails = async () => {
    try {
      let res = await getFantasyAuth(`getpandetails`);
      setPanDetails(res?.data.data);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPanDetails();
  }, [userData]);

  const handleChange = (e) => {
    setFormValues((prev) => ({
      ...prev,
      [e.target.name]: e.target.value,
    }));
  };

  const onChangeFile = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (fileObj) {
        setPanImage(fileObj);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUrl(reader.result);
      };
      reader.readAsDataURL(fileObj);
    }
  };
  const getProfileData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`);
      dispatch(fantasyAddUserData(res?.data?.data));  
    } catch (error) {
      console.log("error",error)
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    var regex = /[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
    if (regex.test(formValues.panCardNumber)) {
      const formdata = new FormData();
      formdata.append("pannumber", formValues.name);
      formdata.append("panname", formValues.panCardNumber);
      formdata.append("dob", formValues.dob);
      formdata.append("typename", "pancard");
      formdata.append("image", panImage);
      try {
        const res = await postFantasyAuthFormData("panrequest", formdata);
        if (res.data.status) {
          succesToaster(res.data.message);
          getProfileData();
        } else {
          errorToaster(res.data.message);
        }
      } catch (error) {
        errorToaster("Something went wrong !");
      }
    } else {
      errorToaster("Please Enter Pan Number in format: ABCDE1234R");
    }
  }

  const validates = (name, panCardNumber, dob, regex) => {
    const errors = {};

    if (!name?.trim()) {
      errors.name = "Name is required";
    } else if (!regex.name.test(name)) {
      errors.name = "Special character are not allowed";
    }

    if (!panCardNumber?.trim()) {
      errors.panCardNumber = "Pan number is required";
    } else if (!regex.pancard.test(panCardNumber)) {
      errors.panCardNumber = "character must be upper eg. ABCDE1234F";
    }

    if (!dob?.trim()) {
      errors.dob = "Date of birth is required";
    } else if (!regex.dob.test(dob)) {
      errors.dob = "date in the format yyyy-mm-dd";
    }
    return errors;
  };

  useEffect(() => {
    setFormErrors(
      validates(
        formValues.name,
        formValues.panCardNumber,
        formValues.dob,
        regex
      )
    );
    // eslint-disable-next-line
  }, [formValues.name, formValues.panCardNumber, formValues.dob]);
  return (
    <div className="row mx-0 pt-3 g-3">
      {/* when mobile and email is not verified */}
      {userData?.mobileVerified !== 1 || userData?.emailVerified !== 1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={panCard} className="w-100" alt="" />
                </div>
                <div className="verifyMobile verifyPanDetails">
                  Verify Your PAN Details
                </div>
              </div>
              <div className="col-12 mobileLabel">
                To update your details please complete the steps mentioned
                below:
              </div>
              <div className="col-12">
                <ul className="mobileLabel">
                  <li>Verify your mobile number.</li>
                  <li>Verify your Email.</li>
                  <li>Complete Your Profile.</li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* when mobile and email is verified but pan isn't */}
      {userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 &&
      userData?.PanVerified === -1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard verifyPanCard">
            <form className="card-body row mx-0 px-2 g-2" name="hello" >
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={panCard} className="w-100" alt="" />
                </div>
                <div className="verifyMobile verifyPanDetails">
                  Verify Your PAN Details
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="idCard d-inline-block">
                  <img
                    src={typeof panImage === "object" ? url : panImage}
                    className="h-100"
                    alt="ID Card"
                  />
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="upload" className="otpBtnn text-center">
                  Upload PAN CARD Image
                </label>
                <input
                  type="file"
                  id="upload"
                  className="form-control shadow-none inp"
                  placeholder="Date of birth*"
                  hidden
                  onChange={(e) => {
                    onChangeFile(e);
                  }}
                />
                <div className=" mobileLabel text-center">
                  Image size up to 4mb*
                </div>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Name*"
                  name="name"
                  onChange={handleChange}
                  value={formValues.name}
                  onFocus={() => setFormOnFocus({ ...formOnFocus, name: true })}
                />
                <div className="otpverfication text-muted mt-1 fw-bold">
                  As on PAN CARD
                </div>
                {formErrors.name && formOnFocus.name ? (
                  <div className="text-danger">{formErrors.name}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="PAN number*"
                  name="panCardNumber"
                  maxLength={10}
                  onChange={handleChange}
                  value={formValues.panCardNumber}
                  onFocus={() =>
                    setFormOnFocus({ ...formOnFocus, panCardNumber: true })
                  }
                />
                <div className="otpverfication text-muted mt-1 fw-bold">
                  Your 10 digit PAN Number
                </div>
                {formErrors.panCardNumber && formOnFocus.panCardNumber ? (
                  <div className="text-danger">{formErrors.panCardNumber}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="date"
                  className="form-control shadow-none inp"
                  placeholder="Date of birth*"
                  name="dob"
                  onChange={handleChange}
                  value={formValues.dob}
                  max={disableYear}
                  onFocus={() => setFormOnFocus({ ...formOnFocus, dob: true })}
                />
              </div>
              <div className="otpverfication text-muted mt-1 fw-bold">
                Your date of BIRTH
              </div>
              {formErrors.dob && formOnFocus.dob ? (
                <div className="text-danger">{formErrors.dob}</div>
              ) : (
                ""
              )}
              <div className="mobileLabel text-center">
                *All fields are mandaory
              </div>

              <div className="col-12">
                <button
                  type="button"
                  className="otpBtnn w-100 text-center"
                  onClick={handleSubmit}
                >
                  Submit for Verification
                </button>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* when mobile and email is verified and pan details are submitted but not verified */}
      {userData?.PanVerified === 0  && userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1  ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body pb-0 row mx-0 px-0 gap-2">
              <div className="col-12 fs-6 verifyMobile verifyPanDetails">
                PAN Card
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Name</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.panname}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">PAN Number</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.pannumber}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">DOB</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.pandob}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="w-50 mx-auto">
                  <img src={panDetails?.image ? panDetails?.image : panCardPic } className="w-100" alt="" />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* pan is verified */}
      {userData?.PanVerified === 1 && userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body pb-0 row mx-0 px-0 gap-2">
              <div className="col-12 fs-6 verifyMobile verifyPanDetails">
                PAN Card details verified
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Name</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.panname}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">PAN Number</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.pannumber}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">DOB</div>
                  <div className="col-7 verifyMobileSub">
                    {panDetails?.pandob}
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="w-50 mx-auto">
                  <img src={panDetails?.image ? panDetails?.image : panCardPic } className="w-100" alt="" />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      {userData?.PanVerified === 2 && userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 ? (
        <>
          <div className="col-12 px-0">
            <div className="card border-0 mobileCard mobileCardInner">
              <form className="card-body pb-0 row mx-0 px-0 gap-2">
                <div className="col-12 fs-6 verifyMobile verifyPanDetails">
                  PAN Card
                </div>
                <div className="col-12 pt-2 px-0 bottomBody">
                  <div className="row mx-0 align-items-center">
                    <div className="col-5 verifyMobile">Name</div>
                    <div className="col-7 verifyMobileSub">
                      {panDetails?.panname}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-2 px-0 bottomBody">
                  <div className="row mx-0 align-items-center">
                    <div className="col-5 verifyMobile">PAN Number</div>
                    <div className="col-7 verifyMobileSub">
                      {panDetails?.pannumber}
                    </div>
                  </div>
                </div>
                <div className="col-12 pt-2 px-0 bottomBody">
                  <div className="row mx-0 align-items-center">
                    <div className="col-5 verifyMobile">DOB</div>
                    <div className="col-7 verifyMobileSub">
                      {panDetails?.pandob}
                    </div>
                  </div>
                </div>
                <div className="col-12">
                  <div className="w-50 mx-auto">
                    <img src={panDetails?.image ? panDetails?.image : panCardPic } className="w-100" alt="" />
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-12 px-0">
            <div className="card border-0 mobileCard verifyPanCard">
              <form className="card-body row mx-0 px-2 g-2">
                <div className="col-12 d-flex align-items-center gap-2">
                  <div className="mobileImg">
                    <img src={panCard} className="w-100" alt="" />
                  </div>
                  <div className="verifyMobile verifyPanDetails">
                    Verification failed, Try Again
                  </div>
                </div>
                <div className="col-12 text-center">
                  <div className="idCard d-inline-block">
                    <img
                      src={typeof panImage === "object" ? url : panImage}
                      className="h-100"
                      alt="ID Card"
                    />
                  </div>
                </div>
                <div className="col-12">
                  <label htmlFor="upload" className="otpBtnn text-center">
                    Upload PAN CARD Image
                  </label>
                  <input
                    type="file"
                    id="upload"
                    className="form-control shadow-none inp"
                    placeholder="Date of birth*"
                    hidden
                    onChange={(e) => {
                      onChangeFile(e);
                    }}
                  />
                  <div className=" mobileLabel text-center">
                    Image size up to 4mb*
                  </div>
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control shadow-none inp"
                    placeholder="Name*"
                    name="name"
                    onChange={handleChange}
                    value={formValues.name}
                    onFocus={() =>
                      setFormOnFocus({ ...formOnFocus, name: true })
                    }
                  />
                  <div className="otpverfication text-muted mt-1 fw-bold">
                    As on PAN CARD
                  </div>
                  {formErrors.name && formOnFocus.name ? (
                    <div className="text-danger">{formErrors.name}</div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control shadow-none inp"
                    placeholder="PAN number*"
                    name="panCardNumber"
                    onChange={handleChange}
                    value={formValues.panCardNumber}
                    onFocus={() =>
                      setFormOnFocus({ ...formOnFocus, panCardNumber: true })
                    }
                  />
                  <div className="otpverfication text-muted mt-1 fw-bold">
                    Your 10 digit PAN Number
                  </div>
                  {formErrors.panCardNumber && formOnFocus.panCardNumber ? (
                    <div className="text-danger">
                      {formErrors.panCardNumber}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-12">
                  <input
                    type="date"
                    className="form-control shadow-none inp"
                    placeholder="Date of birth*"
                    name="dob"
                    onChange={handleChange}
                    value={formValues.dob}
                    onFocus={() =>
                      setFormOnFocus({ ...formOnFocus, dob: true })
                    }
                    max={disableYear}
                  />
                </div>
                <div className="otpverfication text-muted mt-1 fw-bold">
                  Your date of BIRTH
                </div>
                {formErrors.dob && formOnFocus.dob ? (
                  <div className="text-danger">{formErrors.dob}</div>
                ) : (
                  ""
                )}
                <div className="mobileLabel text-center">
                  *All fields are mandaory
                </div>

                <div className="col-12">
                  <button
                    type="button"
                    className="otpBtnn w-100 text-center"
                    onClick={handleSubmit}
                  >
                    Submit for Verification
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default PanCard;
