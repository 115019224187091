import React, { useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import Mobile from './Mobile'
import PanCard from './PanCard'
import Bank from './Bank'
import { useSelector } from 'react-redux'

const VerifyAccount = () => {
    const [activeTab, setActiveTab] = useState('mobile')
    const fantasyUserData = useSelector((state) => state?.fantasyUserData);

    return (
        <>
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'Verify Account'}/>
                <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row align-items-center justify-content-between contestOptionsTabInner verifyAccount">
                <div className="col-4">
                    <div 
                    onClick={()=> setActiveTab('mobile')}
                    className={`contestOptionTab text-center text-uppercase ${activeTab === 'mobile' ? 'active' : ''}`}>Mobile & Email</div>
                </div>
                <div className="col-4">
                    <div 
                    onClick={()=> setActiveTab('pancard')} 
                    className={`contestOptionTab text-center text-uppercase ${activeTab === 'pancard' ? 'active' : ''}`}>Pan Card</div>
                </div>
                <div className="col-4">
                    <div 
                    onClick={()=> setActiveTab('bank')}
                    className={`contestOptionTab text-center text-uppercase ${activeTab === 'bank' ? 'active' : ''}`}>Bank</div>
                </div>
                </div>
                {
                activeTab === 'mobile' ? <Mobile userData={fantasyUserData} /> :
                activeTab === 'pancard' ? <PanCard userData={fantasyUserData} /> :
                activeTab === 'bank' ? <Bank userData={fantasyUserData} /> : ''
                }

                {/* <button className='createTeamBtn' onClick={()=> navigate(ROUTES_CONST?.CREATE_TEAM)}>Create Team</button> */}
                </div>  
            <FantasyBottomBar/>
        </div>
        </>
    )
}

export default VerifyAccount