import React from "react";
import { BrowserRouter } from "react-router-dom";
import AuthContextProvider from "../context/authContext";
import ModalContextProvider from "../context/registerModalContext";
import SignInModalContextProvider from "../context/signInContext";
import { ToastContainer } from "react-toastify";
import SignUpModalContextProvider from "../context/signUpContext";
import WalletModalContextProvider from "../context/walletModalContext";
import UserModalContextProvider from "../context/userModalContext";
import CountryProvider from "../context/countryContext";
import { Provider as ReduxProvider } from "react-redux";
import store from "../store";
import AuthModalContextProvider from "../context/AuthModalContext";
import DownloadModalContextProvider from "../context/downloadModalContext";
import LogoutModalContextProvider from "../context/LogoutContext";
import LoaderContextProvider from "../context/LoaderContext.js"
import { GoogleOAuthProvider } from '@react-oauth/google';
import { clientId } from "../constants/index.js";

const Providers = ({ children }) => {
  return (
    <BrowserRouter basename="/">
      <ReduxProvider store={store}>
        <GoogleOAuthProvider
          clientId={clientId}>
          <LoaderContextProvider>
            {/* <ReferralModalContextProvider> */}
              <AuthModalContextProvider>
                <LogoutModalContextProvider>
                  <AuthContextProvider>
                    {/* <BtAuthContextProvider> */}
                      <ModalContextProvider>
                        <SignUpModalContextProvider>
                          <SignInModalContextProvider>
                            <WalletModalContextProvider>
                              <UserModalContextProvider>
                                <DownloadModalContextProvider>
                                  <CountryProvider>{children}</CountryProvider>
                                </DownloadModalContextProvider>
                              </UserModalContextProvider>
                            </WalletModalContextProvider>
                            <ToastContainer />
                          </SignInModalContextProvider>
                        </SignUpModalContextProvider>
                      </ModalContextProvider>
                    {/* </BtAuthContextProvider> */}
                  </AuthContextProvider>
                </LogoutModalContextProvider>
              </AuthModalContextProvider>
            {/* </ReferralModalContextProvider> */}
          </LoaderContextProvider>
        </GoogleOAuthProvider>
      </ReduxProvider>
    </BrowserRouter>
  );
};

export default Providers;
