import { useLocation, useNavigate } from "react-router-dom";
import RoutesComp from "./routes/Routes";
import { useEffect } from "react";
import { useState } from "react";
import {  AUTH_MODAL_TABS } from "./constants";
import { useAuthModal } from "./hooks/useAuthModal";
import { ROUTES_CONST } from "./constants/routeConstant";
import { useFantasyLoader } from "./hooks/useFantasyLoader";
import FantasyLoader from "./components/fantasyLoader/FantasyLoader";

function App() {
  const [referraldata, setReferral] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const { setAuthModalObject } = useAuthModal()
  const { showFantasyLoader, setShowFantasyLoader } = useFantasyLoader()
  const referralFun = () => {
    setReferral(location.pathname);
  };
  const splitBySlash = referraldata.split("/");
  const lastPart = splitBySlash[splitBySlash.length - 1];
  const extractedStart = lastPart.startsWith("i-") ? "i-" : "";
  const extractedEnd = lastPart.endsWith("-n") ? "-n" : "";
  const middlePart = lastPart.substring(2, lastPart.length - 2);
  const modifiedLink = referraldata.replace(
    "/" + extractedStart + middlePart + extractedEnd,
    ""
  );

  // open register section with referal code.
  useEffect(() => {
    if (extractedStart && extractedEnd) {
      localStorage.setItem("middlePart", middlePart);
      setAuthModalObject(pre => ({
        isAuthOpen: true,
        selectedTab: AUTH_MODAL_TABS.SIGN_UP
      }))
      // navigate("/games", { state: { extractedStart: extractedStart } });
      navigate(ROUTES_CONST.INDEX, { state: { extractedStart: extractedStart } });
    } else {
      localStorage.setItem("middlePart", "");
    }
    referralFun();
  }, [extractedStart, extractedEnd]);


  useEffect(() => {
    setShowFantasyLoader(true);
    let timeout

    timeout = setTimeout(() => {
      setShowFantasyLoader(false);
    }, 3000);
    return ()=>{
      clearTimeout(timeout)
    }
  }, [])

  // scroll to top everytime url changes
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    })
  }, [location.pathname])

  // conditional style for admin and fantasy pages
  useEffect(() => {
    import('./fantasy/fantasyAssets/scss/style.scss')
  }, [location.pathname])

  return (
    <>
      {showFantasyLoader ? <FantasyLoader /> : ''}
      <RoutesComp />
    </>
  );
}

export default App;
