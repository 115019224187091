import { Formik } from 'formik'
import React, { useEffect, useRef, useState } from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import * as Yup from "yup";
import ErrorMessage from '../../../components/common/ErrorMessage/ErrorMessage';
import { postFantasyAPI } from '../../fantasyServices';
import { useSelector } from 'react-redux';
import { errorToaster, succesToaster } from '../../../utils/toaster'
import { useAuth } from '../../../hooks/useAuth';

const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    email: Yup.string()
        .required("This field is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
    password: Yup.string().required("This field is required"),
    phone: Yup.string()
        .required("This field is required")
        .matches(
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            /^[0-9]{10}$/,
            "Invalid mobile number"
        ),
});

const ForgotOtp = () => {
    const [otp, setOtp] = useState(["", "", "", ""]);
    const navigate = useNavigate()
    const tempUser = useSelector(state => state?.tempUser)
    const inputRefs = useRef([]);
    const { token, setToken } = useAuth()
    const [counter, setCounter] = useState(30)
    const counterRef = useRef()
    const location = useLocation()


    useEffect(() => {
        if (!location?.state) {
            navigate(ROUTES_CONST.FANTASY_FORGOT_PASSWORD)

        }
    }, [location?.state])


    useEffect(() => {
        if (counterRef.current) {
            clearInterval(counterRef.current)
        }
        counterRef.current = setInterval(() => {
            setCounter(pre => pre - 1)
        }, 1000)
        return () => {
            clearInterval(counterRef.current)
        }

    }, [])

    useEffect(() => {
        inputRefs.current[0].focus()
    }, [])

    const handleKeyDown = (event, index) => {
        if (event.key === "Backspace" && index > 0 && event.target.value === "") {
            inputRefs.current[index - 1].focus();
        }
    };

    const handleOtpChange = (e, index) => {
        const updatedOtp = [...otp];
        updatedOtp[index] = e.target.value;
        setOtp(updatedOtp);

        if (e.target.value !== "" && index < inputRefs.current.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };
    // add-tempUser
    const handleSubmit = async () => {
        try {
            const payload = {
                // tempuser: tempUser,
                email: location?.state,
                code: otp.join("")
            }
            const res = await postFantasyAPI('matchCodeForReset', payload)
            if (res.data.success) {
                navigate(ROUTES_CONST.FANTASY_CHANGE_PASSWORD, {
                    state: { data: res.data.data.suerid },
                });
                // navigate(ROUTES_CONST.FANTASY_CHANGE_PASSWORD)
                // setToken(res.data?.data?.token)
            } else {
                errorToaster(res.data?.message)
            }
        } catch (error) {

        }
    }

    const resendHandler = async (values) => {
        try {
            const payload = {
                email: location.state,
            }
            const res = await postFantasyAPI('forgotpassword', payload)
            if (res.data.success) {
                // setToken(res.data.data?.token)
                // navigate(ROUTES_CONST.FANTASY_FORGOT_OTP)
                succesToaster(res.data.message)
                setCounter(30)

                // localStorage.setItem(FANTASY_TOKEN_NAME, res.data.data?.token)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {

        }
    }
    return (
        <div className="row fantasyInner">
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className='d-flex justify-content-center text-light pt-4'>
                    <div>
                        OTP
                    </div>
                </div>

                <form >
                    <div className="row mx-0 py-3 depositForm gap-3 d-flex justify-content-between ">
                        {
                            otp.map((item, index) => (
                                <div className="col-2">
                                    <input
                                        type="text"
                                        className="form-control shadow-none inp py-2"
                                        id="addcasevc"
                                        placeholder=""
                                        value={item}
                                        key={index}
                                        maxLength={1}
                                        onChange={(e) => handleOtpChange(e, index)}
                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                        onKeyDown={(event) => {
                                            if (event.key === "Enter") {
                                                const otpValue = otp.join("");
                                                if (otpValue.length !== 6) {
                                                    errorToaster("OTP Field can't be empty")
                                                    return
                                                }
                                                //   setIsLoading(true)
                                                handleSubmit(event)
                                            }
                                            handleKeyDown(event, index)
                                        }}

                                    />

                                </div>
                            ))
                        }
                        <div className="col-12">
                            <button type="button" className=" btnStyle2"
                                onClick={handleSubmit}
                            >Confirm Otp</button>
                        </div>
                        <div className='text-light d-flex justify-content-center align-items-center'>
                            Resend in {counter <= 0 ? (
                                <span
                                    className='m-1'
                                    onClick={() => {
                                        resendHandler()
                                    }}
                                >
                                    RESEND OTP
                                </span>
                            ) : counter}
                        </div>
                    </div>
                </form>
                {/* )
                    }

                </Formik> */}
                {/* <div className='text-light m-2'>Already have an account?
                    <NavLink
                        className={'text-light mx-1'}
                        to={ROUTES_CONST.FANTASY_LOGIN}
                    >
                        Login
                    </NavLink>
                </div> */}
            </div>
        </div>
    )
}

export default ForgotOtp