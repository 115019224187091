import React, { useState, useEffect } from 'react'
import { useAuth } from '../../hooks/useAuth'
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES_CONST } from '../../constants/routeConstant';

const FantasyProtectedRoute = (props) => {
    const { Component } = props
    const { token } = useAuth();
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        if (token && (location.pathname === ROUTES_CONST.FANTASY_REGISTER || location.pathname === ROUTES_CONST.FANTASY_REGISTER_OTP || location.pathname === ROUTES_CONST.FANTASY_LOGIN)) {
            navigate(ROUTES_CONST.FANTASY_HOME)
        } else if (!token) {
            if (location.pathname === ROUTES_CONST.FANTASY_REGISTER || location.pathname === ROUTES_CONST.FANTASY_REGISTER_OTP || location.pathname === ROUTES_CONST.FANTASY_LOGIN || location.pathname === ROUTES_CONST.FANTASY_FORGOT_PASSWORD ||location.pathname === ROUTES_CONST.FANTASY_FORGOT_OTP || location.pathname === ROUTES_CONST.FANTASY_CHANGE_PASSWORD) {

            } else {
                navigate(ROUTES_CONST.FANTASY_LOGIN)
            }
        }
    }, [token])

    return (
        // Loading ? (<Spinner />) : (isLoggedIn && <Component />)
        <Component />

    )
}

export default FantasyProtectedRoute