import React, { useEffect, useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import FantasyBottomBar from '../../fantasyComponents/fantasyBottombar/FantasyBottomBar'
import { getFantasyAuth } from '../../fantasyServices'
import moment from 'moment/moment'
import notify from '../../fantasyAssets/img/notify.svg'


const Notification = () => {

    const [notifications, setnotifications] = useState([])
    const [loader, setLoader] = useState(true)


    const notificationData = async () => {
        setLoader(true)
        try {
            const res = await getFantasyAuth(`getnotification?start=0&limit=20`);
            if (res?.data?.status) {
                setnotifications(res.data.data)
            }
        } catch (error) {
        } finally {
            setLoader(false)
        }
    }
    useEffect(() => {
        notificationData()
    }, [])
    console.log('notifications', notifications)

    return (
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'Notification'} />
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row mainCenterPart g-4 mx-0 py-4">
                    {/* <div className='today'>TODAY</div> */}
                    {
                        !loader ?
                            notifications?.length > 0 ?
                                notifications?.map((item) => (
                                    <div className="col-12">
                                        <div className="warningCard addcase card border-0 rounded-4 position-relative">
                                            <div className="card-body">
                                                <div className="row mx-0 align-items-center">
                                                    <div className="col-auto ps-0">
                                                        <div className="circle notificationCircle">
                                                            <img src={notify} />
                                                        </div>
                                                    </div>
                                                    <div className="col ps-0">
                                                        {/* <div href="" className="sender ">Tiny<span>Now</span></div> */}
                                                        <div className='bonus'>{item?.title}</div>
                                                        <div className='slide'><span>{moment(item?.createdAt).format("MMM Do YY")}</span></div>
                                                    </div>
                                                    {/* <div className='col-auto'><img className='checkImg' src=''/></div> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                                : 'no notifications'
                            : 'loader'
                    }
                    {/* <div className='today'>OLDER</div>
                        <div className="col-12">
                            <div className="warningCard addcase card border-0 rounded-4 position-relative">
                                <div className="card-body">
                                    <div className="row mx-0 align-items-center">
                                        <div className="col-auto ps-0">
                                            <div className="circle ">
                                            </div>
                                        </div>
                                        <div className="col">
                                            <div href="" className="sender ">Tiny<span>9 min ago</span></div>
                                            <div className='bonus'>You have get rs 40 for mobile bonus</div>
                                            <div className='slide'><span>Slide to view</span></div>
                                        </div>
                                        <div className='col-auto'><img className='checkImg' src=''/></div>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                </div>
            </div>
            <FantasyBottomBar />
        </div>
    )
}

export default Notification