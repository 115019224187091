export const ROUTES_CONST = {
    // fantasy pages
    FANTASY_HOME: '/',
    FANTASY_DETAIL: '/fantasy-detail',
    CREATE_TEAM: '/create-team',
    VIEW_TEAM: '/view-team',
    SELECT_CAPTAIN_VICECAPTAIN: '/select-captains',
    MY_MATCHES: '/my-matches',
    MY_MATCHES_CONTEST: '/my-matches-contest',
    FANTASY_WALLET: '/wallet',
    FANTASY_DEPOSIT_CASH: '/deposit-cash',
    FANTASY_TRANSACTION: '/transaction',
    FANTASY_LEADERbOARD: '/leaderboard',
    FANTASY_MATCH_DETAIL: '/fantasy-match-detail',
    FANTASY_WITHDRAW: '/withdraw',
    MY_TEAMS_PAGE: '/my-teams-page',
    CONTEST_DETAILS_PAGE: '/contest-details-page',
    FANTASY_SELECT_STOCKS_PAGE: '/select-stocks-page',

    FANTASY_INVITE_FRIEND: '/invite-friend',
    FANTASY_VERIFY_ACCOUNT: '/verify-account',
    FANTASY_PROFILE: '/profile',
    FANTASY_PLAY_INSIGHT_PROFILE: '/play-insight',
    FANTASY_MORE: '/more',
    FANTASY_CONTACT_HELP: '/contact-help',
    FANTASY_TERM_AND_CONDITION: '/terms-and-condition',
    FANTASY_MY_PORTFOLIO: '/my-portfolio',
    FANTASY_NOTIFICATION: '/notification',
    FANTASY_STOCK_USABLE_BALANCE: '/Stock-usable-balance',
    FANTASY_STOCK_LIST: '/stock-list',
    FANTASY_STOCK_LEADERBOARD: '/stock-leaderBoard',
    FANTASY_REGISTER: '/register',
    FANTASY_LOGIN: '/login',
    FANTASY_REGISTER_OTP: '/register-otp',
    FANTASY_FORGOT_PASSWORD: '/forgot-password',
    FANTASY_FORGOT_OTP: '/forgot-password-otp',
    FANTASY_CHANGE_PASSWORD: '/change-password',

}