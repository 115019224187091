import { Formik } from 'formik'
import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import * as Yup from "yup";
import ErrorMessage from '../../../components/common/ErrorMessage/ErrorMessage';
import { postFantasyAPI } from '../../fantasyServices';
import { errorToaster } from '../../../utils/toaster';

const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    // email: Yup.string()
    //     .required("This field is required")
    //     .matches(
    //         /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
    //         "Invalid email"
    //     ),
    password: Yup.string().required("This field is required"),
    confirmPassword: Yup.string().required("This field is required"),
});
const ChangePassword = () => {
    const navigate = useNavigate()
    const location = useLocation();


    const handleSubmit = async (values) => {

        if (values.confirmPassword !== values.password) {
            errorToaster("Password doesn't match")
            return
        }
        try {

            const payload = {
                password: values.password,
                suerid: location?.state?.data,
            };
            const res = await postFantasyAPI('resetpassword', payload)
            if (res.data.success) {
                navigate(ROUTES_CONST.FANTASY_LOGIN)
                // localStorage.setItem(FANTASY_TOKEN_NAME, res.data.data?.token)
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {

        }
    }
    return (
        <div className="row fantasyInner">
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className='d-flex justify-content-center text-light pt-4'>
                    <div>
                        Change Password
                    </div>
                </div>
                <Formik
                    initialValues={{
                        password: "",
                        confirmPassword: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {
                        ({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                        }) => (
                            <form >
                                <div className="row mx-0 py-3 depositForm gap-4 ">
                                    <div className="col-12">
                                        <label htmlFor="addcase1112" className="form-label labelHeading addText">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase1112"
                                            placeholder="Your new password"
                                            name='password'
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.password &&
                                                touched.password &&
                                                errors.password
                                            }
                                        </ErrorMessage>
                                    </div>

                                    <div className="col-12">
                                        <label htmlFor="addcase123" className="form-label labelHeading addText">
                                            Confirm password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase123"
                                            placeholder="Confirm your password"
                                            name='confirmPassword'
                                            value={values.confirmPassword}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.confirmPassword &&
                                                touched.confirmPassword &&
                                                errors.confirmPassword
                                            }
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-12">
                                        <button
                                            type="button"
                                            className=" btnStyle2"
                                            onClick={handleSubmit}
                                        >Change Password</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }

                </Formik>

            </div>
        </div>
    )
}

export default ChangePassword