import React, { useEffect, useState } from 'react'
import FantasyHeader from '../../fantasyComponents/fantasyHeader/FantasyHeader'
import { getFantasyAuth, postFantasyAuth } from '../../fantasyServices'
import moment from 'moment/moment'
import doller from '../../../fantasy/fantasyAssets/img/dollerSign.svg'
import trophyImg from '../../fantasyAssets/img/trophyIcon.png'
import user from '../../../fantasy/fantasyAssets/img/ban.png'
import { useLocation, useNavigate } from 'react-router-dom'
import CounterComp from '../../fantasyComponents/CounterComp/CounterComp'
import { Spinner } from 'react-bootstrap'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import { errorToaster, succesToaster } from '../../../utils/toaster'

const StockUsableBalance = () => {
    const companyData = useLocation()?.state?.companyData
    const stockContest = useLocation()?.state?.stockContest
    const [balance, setBalance] = useState([]);
    const [loader, setLoader] = useState(true);
    const [joinLoader, setJoinLoader] = useState(false)
    console.log({ balance })
    const navigate = useNavigate()

    const getBalance = async () => {
        try {
            const res = await getFantasyAuth(`/get-stock-usable-balance?contestId=${stockContest._id}`,);
            let addedCash = 0;
            if (Number(res.data?.data?.entryfee) > Number(res.data?.data?.usablebalance)) {
                addedCash = res.data.data.entryfee - res.data.data.usablebalance;
            }
            const DetailData = [
                { title: 'Entry', coins: res.data.data.entryfee },
                { title: 'My Balance', coins: res.data.data.usablebalance },
                { title: 'Bonus', coins: res.data.data.bonus },
                { title: 'Add', coins: addedCash },
            ];
            setBalance(DetailData);
        } catch (error) {
            //error
        } finally {
            setLoader(false);
        }

    };

    const JoinStocks = async () => {
        setJoinLoader(true)
        try {
            let teamParams = {
                stock: [
                    {
                        stockId: companyData[0]?._id,
                        percentage: companyData[0]?.percentage,
                        instrument_token: companyData[0]?.instrument_token,
                    },
                    {
                        stockId: companyData[1]?._id,
                        percentage: companyData[1]?.percentage,
                        instrument_token: companyData[1]?.instrument_token,
                    },
                ],
                teamnumber: 1,
                contestId: stockContest?._id,
            };
            const createTeam = await postFantasyAuth('stock-create-team', teamParams);
            if (createTeam.data?.success) {
                let params = {
                    stockTeamId: createTeam?.data?.data?.teamid,
                    stockContestId: stockContest?._id,
                    // contestType: stockContest?.type,
                    contestType: "STOCKS",

                };
                const res = await postFantasyAuth('join-stock-contest', params);
                console.log("createTeam", '2api', res);

                if (res?.status) {
                    succesToaster(res?.message);
                    navigate(ROUTES_CONST.FANTASY_HOME, { data: companyData });
                } else {
                    errorToaster(res.message);
                    navigate(ROUTES_CONST.FANTASY_HOME, { data: companyData });
                }
            }
        } catch (error) {

        } finally {
            setJoinLoader(false)
        }

    };
    useEffect(() => {
        getBalance()
    }, [])
    return (
        <div className="row fantasyInner">
            <FantasyHeader gameHeader={true} title={'Stock Usable Balance'} />
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className="row mainCenterPart gy-2 mx-0">
                    <div class="col-12 mb-3 mt-2 cursor-pointer pt-4 pb-0">
                        <div class="row mx-0 contestCard">
                            <div class="col-12 mb-2">
                                <div class="row align-items-center justify-content-between">
                                    <div class="winnerPrz fw-light fs-6 col-auto"><img className='me-2 tMin' src={trophyImg} />{stockContest?.winning_percentage}%</div>
                                    <div class="col-auto">
                                        <div class="winnerPrz"><img className='me-2 tImg' src={trophyImg} />{stockContest?.win_amount}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row align-items-center justify-content-between">
                                    <div class="  col-auto">
                                        <div className='userImg'><img src={user} />
                                        </div></div>
                                    <div className='col d-flex justify-content-center text-danger'>
                                        {/* 03d:17h:19m */}
                                        <CounterComp start_date={stockContest?.start_date} stocks={true} />
                                    </div>
                                    <div class="col-auto">
                                        <div class="entryPrz">₹{stockContest?.entryfee}</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row align-items-center justify-content-between">
                                    <div class="col-12 mt-2 mb-1">
                                        <div class="progress spotProgress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar progress-bar-striped" style={
                                                {
                                                    width: `${stockContest?.maximum_user == 0 ? 0 : stockContest?.joinedusers / stockContest?.maximum_user * 100}%`
                                                }
                                            }>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div class="col leftSpots text-danger">2 Spots Left </div> */}
                                </div>
                            </div>
                            <div class="col-12 contestCardFooter mt-2 py-2">
                                <div class="row align-items-center">
                                    <div class="col-4 d-flex align-items-center pe-0"><div class="gurantyTxt me-2">Starts</div>
                                        <div class="gurantyTxt fw-bold">
                                            {/* 5 Nov | 10:B */}
                                            {
                                                stockContest?.start_date ? moment(stockContest?.start_date).format("DD MMM | HH:mm") : "-----"
                                            }
                                        </div>
                                    </div>
                                    <div class="col-4 d-flex align-items-center justify-content-center"><div class="gurantyTxt me-2">
                                        Spots Left:</div>
                                        <div class="gurantyTxt fw-bold">
                                            {!isNaN(stockContest?.maximum_user - stockContest?.joinedusers) ? (stockContest?.maximum_user - stockContest?.joinedusers) : ('---')}
                                        </div>
                                    </div>
                                    <div class="col-4 d-flex align-items-center justify-content-end  ps-0"><div class="gurantyTxt">Ends: </div>
                                        <div class="gurantyTxt fw-bold">
                                            {/* 30 Nov | 10:B */}
                                            {
                                                stockContest?.end_date ? moment(stockContest?.end_date).format("DD MMM | HH:mm") : "----"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12'>
                        <div className='row stockBg mx-0'>
                            <div className="col-12 d-flex justify-content-between align-items-center mainCenterPart mx-0 pb-2">
                                <div className='text-white'>Detail</div>
                                <div className='dollerImg'><img src={doller} /></div>
                            </div>
                            <div className="col-12 px-0">
                                <div className="stockCard addcase card cursor-pointer border-0 rounded4 position-relative">
                                    <div className="card-body">
                                        {
                                            loader ? (
                                                <div className='w-100 h-100 d-flex justify-content-center'>
                                                    <Spinner color='white' size='sm' />
                                                </div>
                                            ) : (
                                                !balance || balance == 0 ? (
                                                    <div className='w-100 h-100 d-flex justify-content-center text-light' >No Data Available</div>
                                                ) : (
                                                    balance.map(item => (
                                                        <div className="row mt-2 mx-0 align-items-center justify-content-between"
                                                            key={item?.title}
                                                        >
                                                            <div className="col-9 ps-0">
                                                                <div className="text-white strockText">
                                                                    {/* My Balance */}
                                                                    {item?.title}
                                                                </div>
                                                            </div>
                                                            <div className="col-3 d-flex justify-content-end px-0">
                                                                <div className="portfolioCardTxt">
                                                                    {/* 900.00 */}
                                                                    {item?.coins}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    ))
                                                )
                                            )
                                        }
                                        {/* <div className="row mx-0 align-items-center justify-content-between">
                                            <div className="col-9 ps-0">
                                                <div className="text-white strockText">Entry
                                                </div>
                                            </div>
                                            <div className="col-3 d-flex justify-content-end px-0">
                                                <div className="portfolioCardTxt">50.00</div>
                                            </div>
                                        </div>

                                        <div className="row mt-2 mx-0 align-items-center justify-content-between">
                                            <div className="col-9 ps-0">
                                                <div className="text-white strockText">Bonus
                                                </div>
                                            </div>
                                            <div className="col-3 d-flex justify-content-end px-0">
                                                <div className="portfolioCardTxt">0.00</div>
                                            </div>
                                        </div>
                                        <div className="row mt-2 mx-0 align-items-center justify-content-between">
                                            <div className="col-9 ps-0">
                                                <div className="text-white strockText">Add
                                                </div>
                                            </div>
                                            <div className="col-3 d-flex justify-content-end px-0">
                                                <div className="portfolioCardTxt">0.00</div>
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <button className='createTeamBtn'
                    onClick={() => {
                        if (balance.length && balance[3].coins) {
                            navigate(ROUTES_CONST.FANTASY_DEPOSIT_CASH, {
                                add: balance[3].coins,
                            });
                        } else {
                            JoinStocks();
                        }
                    }
                    }
                >
                    {/* Select Stocks */}
                    {
                        joinLoader ? (
                            <Spinner color='white' size='sm' />
                        ) : (
                            <>
                                {
                                    balance?.length && balance[3]?.coins ? 'Add' : 'Join'
                                } {' '}
                                {
                                    balance?.length && balance[3].coins ? (balance?.length ? balance[3].coins : 0) : ("")
                                }
                            </>
                        )
                    }

                </button>
            </div>
            {/* <FantasyBottomBar /> */}
        </div>
    )
}

export default StockUsableBalance

