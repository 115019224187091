import { Formik } from 'formik'
import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ROUTES_CONST } from '../../../constants/routeConstant'
import * as Yup from "yup";
import ErrorMessage from '../../../components/common/ErrorMessage/ErrorMessage';
import { postFantasyAPI } from '../../fantasyServices';
import { useDispatch } from 'react-redux';
import { setTempUser } from '../../../store/action';
import { useAuth } from '../../../hooks/useAuth'
import { errorToaster } from '../../../utils/toaster';
import { GoogleLogin } from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';



const validationSchema = Yup.object({
    // name: Yup.string().required("This field is required"),
    email: Yup.string()
        .required("This field is required")
        .matches(
            /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            "Invalid email"
        ),
    password: Yup.string().required("This field is required"),
    phone: Yup.string()
        .required("This field is required")
        .matches(
            // /^([a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$|^(\+\d{1,3}\s?)?[\d-]{10,}$/i,
            /^[0-9]{10}$/,
            "Invalid mobile number"
        ),
});

const Register = () => {
    const [cPassword, setCPassword] = useState("")
    const [referCode, setReferCode] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { token, setToken } = useAuth()
    // const token = 'YOUR_TOKEN_HERE'; // Replace 'YOUR_TOKEN_HERE' with your actual token

    // const decodedToken = jwtDecode(token);
    // console.log(decodedToken);

    const handleGoogleLogin = async (credentialResponse) => {
        try {
            const userData = jwtDecode(credentialResponse?.credential)
            console.log(userData)
            const payload = {
                email: userData?.email,
                name: userData?.name,
                image: userData?.picture
            }
            const res = await postFantasyAPI('socialauthentication', payload)
            console.log({ res })
            if (res.data.success) {
                navigate(ROUTES_CONST.FANTASY_HOME)
                setToken(res.data?.data?.token)
                // dispatch(setTempUser(res.data.data?.tempUser))
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {
            console.log(error)
        }
    }

    // add-tempUser
    const handleSubmit = async (values) => {
        try {
            if (cPassword !== values.password) {
                errorToaster("Password doesn't match")
                return
            }
            const payload = {
                email: values.email,
                password: values.password
            }
            const res = await postFantasyAPI('add-tempUser', payload)
            if (res.data.success) {
                navigate(ROUTES_CONST.FANTASY_REGISTER_OTP)
                dispatch(setTempUser(res.data.data?.tempUser))
            } else {
                errorToaster(res.data.message)
            }
        } catch (error) {
            errorToaster("Something went wrong")
        }
    }
    return (
        <div className="row fantasyInner">
            <div className="col-12 px-0 flex-fill" data-page-name="indexPage">
                <div className='d-flex justify-content-center text-light pt-4'>
                    <div>
                        Register
                    </div>
                </div>
                <Formik
                    initialValues={{
                        email: "",
                        password: "",
                        phone: ""
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        handleSubmit(values)
                    }}
                >
                    {
                        ({
                            values,
                            errors,
                            touched,
                            handleSubmit,
                            handleChange,
                            handleBlur,
                        }) => (
                            <form >
                                <div className="row mx-0 py-3 depositForm gap-4 ">
                                    {/* <div className="col-12">
                                        <label
                                            htmlFor="addcaseter"
                                            className="form-label labelHeading addText"
                                        >
                                            Name
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control shadow-none inp py-2"
                                            id="addcaseter"
                                            placeholder="Enter Your Name"
                                            name='name'
                                            value={values.name}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />
                                        <ErrorMessage>
                                            {errors.name &&
                                                touched.name &&
                                                errors.name
                                            }
                                        </ErrorMessage>
                                    </div> */}
                                    <div className="col-12">
                                        <label htmlFor="addcasevc" className="form-label labelHeading addText">
                                            Email
                                        </label>
                                        <input
                                            type="email"
                                            className="form-control shadow-none inp py-2"
                                            id="addcasevc"
                                            placeholder="Your Email"
                                            name='email'
                                            value={values.email}
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                        />

                                        <ErrorMessage>
                                            {errors.email &&
                                                touched.email &&
                                                errors.email
                                            }
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="addcase11" className="form-label labelHeading addText">
                                            Phone
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase11"
                                            placeholder="Your Phone"
                                            name='phone'
                                            value={values.phone}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.phone &&
                                                touched.phone &&
                                                errors.phone
                                            }
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="addcase12" className="form-label labelHeading addText">
                                            Password
                                        </label>
                                        <input
                                            type="password"
                                            className="form-control shadow-none inp py-2"
                                            id="addcase12"
                                            placeholder="Your Password"
                                            name='password'
                                            value={values.password}
                                            onChange={handleChange}
                                            onBlur={handleBlur}

                                        />
                                        <ErrorMessage>
                                            {errors.password &&
                                                touched.password &&
                                                errors.password
                                            }
                                        </ErrorMessage>
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="addcasess" className="form-label labelHeading addText">
                                            Confirm Password
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control shadow-none inp py-2"
                                            id="addcasess"
                                            placeholder="Confirm your password"
                                            name='email'
                                            value={cPassword}
                                            onChange={(e) => setCPassword(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <label htmlFor="addcasess12" className="form-label labelHeading addText">
                                            Refer Code
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control shadow-none inp py-2"
                                            id="addcasess12"
                                            placeholder="Your refer code"
                                            name='Refercode'
                                            value={referCode}
                                            onChange={(e) => setReferCode(e.target.value)}
                                        />
                                    </div>
                                    <div className="col-12">
                                        <button type="button" className=" btnStyle2"
                                            onClick={handleSubmit}
                                        >Register</button>
                                    </div>
                                </div>
                            </form>
                        )
                    }

                </Formik>
                <div
                    className='d-flex justify-content-center'
                >
                    <GoogleLogin
                        onSuccess={handleGoogleLogin}
                        onError={() => {
                            console.log('Login Failed');
                        }}
                    />;
                </div>
                <div className='text-light m-2'>Already have an account?
                    <NavLink
                        className={'text-light mx-1'}
                        to={ROUTES_CONST.FANTASY_LOGIN}
                    >
                        Login
                    </NavLink>
                </div>
            </div>
        </div>
    )
}

export default Register