import React, { useEffect, useState } from "react";
import blueBank from "./../../fantasyAssets/img/bankBlue.png";
import passbook from "./../../fantasyAssets/img/passbook.png";
import book from "./../../fantasyAssets/img/book.png";
import { getFantasyAuth, postFantasyAuthFormData } from "../../fantasyServices";
import { errorToaster, succesToaster } from "../../../utils/toaster";
import { useDispatch } from "react-redux";
import { fantasyAddUserData } from "../../../store/action";

const data = [
  { label: "Select your state",value:"" },
  { label: "Arunachal Pradesh",value:"Arunachal Pradesh" },
  { label: "Bihar",value:"Bihar" },
  { label: "Chhattisgarh",value:"Chhattisgarh" },
  { label: "Goa",value:"Goa" },
  { label: "Gujarat",value:"Gujarat" },
  { label: "Haryana",value:"Haryana" },
  { label: "Himachal Pradesh",value:"Himachal Pradesh" },
  { label: "Jharkhand",value:"Jharkhand" },
  { label: "Karnataka" ,value:"Karnataka"},
  { label: "Kerala" ,value:"Kerala"},
  { label: "Madhya Pradesh",value:"Madhya Pradesh" },
  { label: "Maharashtra" ,value:"Maharashtra"},
  { label: "Manipur",value:"Manipur" },
  { label: "Meghalaya" ,value:"Meghalaya"},
  { label: "Mizoram",value:"Mizoram" },
  { label: "Punjab" ,value:"Punjab"},
  { label: "Rajasthan" ,value:"Rajasthan"},
  { label: "Tamil Nadu" ,value:"Tamil Nadu"},
  { label: "Tripura" ,value:"Tripura"},
  { label: "Uttar Pradesh",value:"Uttar Pradesh" },
  { label: "Uttarakhand",value:"Uttarakhand" },
  { label: "West Bengal",value:"West Bengal" },
];

const regex = {
  accountHolderName: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
  ifscCode: /^[A-Z]{4}0[A-Z0-9]{6}$/,
  AccountNumber: /^(?:[0-9]{11}|[0-9]{2}-[0-9]{3}-[0-9]{6})$/,
  bankName: /^[A-Za-z]+(?: [A-Za-z]+)*$/,
};

const Bank = ({ userData }) => {
  const [formValues,setFormValue]=useState({
    accountHolderName:"",
    accountNumber:"",
    verifyAccountNumber:"",
    bankName:"",
    branchName:"",
    ifscCode:"",
    state:""
  })
  const [formErrors,setFormErrors]=useState({
    accountHolderName:"",
    accountNumber:"",
    verifyAccountNumber:"",
    bankName:"",
    branchName:"",
    ifscCode:"",
    state:""
  })
  const [error,setError]=useState("")
  const [formOnBlur,setFormOnBlur]=useState({
    accountHolderName:false,
    accountNumber:false,
    verifyAccountNumber:false,
    bankName:false,
    branchName:false,
    ifscCode:false,
    state:false
  })
  const [panImage, setPanImage] = useState(passbook);
  const [url, setUrl] = useState("");
  const [bankDetails,setBankDetails]=useState({})
  const dispatch=useDispatch()

  const getContest = async () => {
    try {
      let res = await getFantasyAuth(`/getbankdetails`);
      setBankDetails(res.data.data);
    } catch (error) {
      console.log("error",error)
    }
  };

  useEffect(()=>{
    getContest()
  },[userData])

  const onChangeFile = (e) => {
    const fileObj = e.target.files && e.target.files[0];
    if (fileObj) {
        setPanImage(fileObj);
      const reader = new FileReader();
      reader.onloadend = () => {
        setUrl(reader.result);
      };
      reader.readAsDataURL(fileObj);
    }
  };

  const handleChange=(e)=>{
    setFormValue((prev)=>({
        ...prev,
        [e.target.name]:e.target.value
    }))
  }

  const handleSubmit=(e)=>{
    e.preventDefault()
    setFormOnBlur(prev=>({
      accountHolderName:true,
      accountNumber:true,
      verifyAccountNumber:true,
      bankName:true,
      branchName:true,
      ifscCode:true,
      state:true
    }))
    if(!formErrors.accountHolderName && !formErrors.accountNumber && !formErrors.bankName && !formErrors.branchName && !formErrors.ifscCode && !formErrors.state && !formErrors.verifyAccountNumber){
      handleBankVerification()
    }else{
      setError("Please enter valid values")
    }
  }

  const handleBankVerification = async () => {
    const formData = new FormData();
    formData.append('accountholder', formValues.accountHolderName);
    formData.append('accno', formValues.accountNumber);
    formData.append('ifsc', formValues.ifscCode);
    formData.append('bankname', formValues.bankName);
    formData.append('bankbranch', formValues.branchName);
    formData.append('state', formValues.state);
    formData.append('typename', 'bank');
    formData.append('image', panImage);
    try {
      const res = await postFantasyAuthFormData("bankrequest", formData);
      if (res.data.status) {
        succesToaster(res.data.message);
        getProfileData();
        resetAll()
      } else {
        errorToaster(res.data.message);
      }
    } catch (error) {
      errorToaster("Something went wrong !");
    }
  };

  const resetAll=()=>{
    setFormValue({
      accountHolderName:"",
      accountNumber:"",
      verifyAccountNumber:"",
      bankName:"",
      branchName:"",
      ifscCode:"",
      state:""
    })
    setFormOnBlur({
      accountHolderName:false,
      accountNumber:false,
      verifyAccountNumber:false,
      bankName:false,
      branchName:false,
      ifscCode:false,
      state:false
    })
    setFormErrors({
      accountHolderName:"",
      accountNumber:"",
      verifyAccountNumber:"",
      bankName:"",
      branchName:"",
      ifscCode:"",
      state:""
    })
  }

  const getProfileData = async () => {
    try {
      const res = await getFantasyAuth(`userFullDetails`);
      dispatch(fantasyAddUserData(res?.data?.data));
    } catch (error) {
      console.log("error",error)
    }
  };

  useEffect(()=>{
    setFormErrors(validates(
      formValues.accountHolderName,
      formValues.accountNumber,
      formValues.verifyAccountNumber,
      formValues.bankName,
      formValues.branchName,
      formValues.ifscCode,
      formValues.state,
      regex
    ))
  },[formValues.accountHolderName,
    formValues.accountNumber,
    formValues.verifyAccountNumber,
    formValues.bankName,
    formValues.branchName,
    formValues.ifscCode,
    formValues.state,])

    useEffect(()=>{
      if (!formErrors.accountHolderName && !formErrors.accountNumber && !formErrors.bankName && !formErrors.branchName && !formErrors.ifscCode && !formErrors.state && !formErrors.verifyAccountNumber) {
        setError("")
      }
    },[formErrors.accountHolderName,
      formErrors.accountNumber,
      formErrors.verifyAccountNumber,
      formErrors.bankName,
      formErrors.branchName,
      formErrors.ifscCode,
      formErrors.state,])

    const validates = (
      accountHolderName,
      accountNumber,
      verifyAccountNumber,
      bankName,
      branchName,
      ifscCode,
      state,
      regex
    ) => {
      const errors = {};
  
      if (!accountHolderName?.trim()) {
        errors.accountHolderName = "Account holder name is required";
      } else if (!regex.accountHolderName?.test(accountHolderName)) {
        errors.accountHolderName = "Special character and numbers are not allowed";
      }
  
      if (!accountNumber?.trim()) {
        errors.accountNumber = "Account Number is required";
      }
  
      if (!verifyAccountNumber?.trim()) {
        errors.verifyAccountNumber = "Account Number verification is required";
      } else if (verifyAccountNumber !== accountNumber) {
        errors.verifyAccountNumber = "Account number does not match account number";
      }
  
      if (!bankName?.trim()) {
        errors.bankName = "Bank name is required";
      }
  
      if (!branchName?.trim()) {
        errors.branchName = "Bank Branch name is required";
      }
  
      if (!ifscCode?.trim()) {
        errors.ifscCode = "Ifsc code is required";
      } else if (!regex.ifscCode.test(ifscCode)) {
        errors.ifscCode = "Enter a valid Ifdc code  e.g. SBIN0001234 ";
      }
  
      if (!state?.trim()) {
        errors.state = "State name is required";
      }
  
      return errors;
    };

  return (
    <div className="row mx-0 pt-3 g-3">
      {/* when mobile , email and pan is not verified */}
      {userData?.mobileVerified !== 1 ||
      userData?.emailVerified !== 1 ||
      userData?.PanVerified !== 1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={blueBank} className="w-100" alt="" />
                </div>
                <div className="verifyMobile verifyPanDetails">
                  Verify Your Bank Details
                </div>
              </div>
              <div className="col-12 mobileLabel">
                To update your bank details please complete the steps mentioned
                below:
              </div>
              <div className="col-12">
                <ul className="mobileLabel ">
                  <li>Verify your mobile number.</li>
                  <li>Verify your Email.</li>
                  <li>Verify your PAN card</li>
                </ul>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}
      {/* when mobile , email and pan is verified but bank isn't */}
      {userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 &&
      userData?.PanVerified === 1 &&
      userData?.BankVerified === -1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner verifyPanCard">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={blueBank} className="w-100" alt="" />
                </div>
                <div className="verifyMobile verifyPanDetails ">
                  Verify Your BANK Details
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="idCard d-inline-block">
                  <img src={typeof panImage === "object" ? url : panImage} className="h-100" alt="ID Card" />
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="upload" className="otpBtnn text-center">
                  Upload Bank account Proof
                </label>
                <input
                  type="file"
                  id="upload"
                  className="form-control shadow-none inp"
                  hidden
                  onChange={onChangeFile}
                />
                <div className=" mobileLabel text-center">
                  Image size up to 4mb*
                </div>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Account Holder Name*"
                  name="accountHolderName"
                  onChange={handleChange}
                  value={formValues.accountHolderName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,accountHolderName:true }))}
                />
                {formErrors.accountHolderName && formOnBlur.accountHolderName ? (
                  <div className="text-danger fs-7">{formErrors?.accountHolderName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Account Number*"
                  name="accountNumber"
                  onChange={handleChange}
                  value={formValues.accountNumber}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,accountNumber:true }))}
                />

                {formErrors.accountNumber && formOnBlur.accountNumber ? (
                  <div className="text-danger fs-7">{formErrors?.accountNumber}</div>
                ) : (
                  ""
                )}
              </div>

              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Verify Account Number*"
                  name="verifyAccountNumber"
                  onChange={handleChange}
                  value={formValues.verifyAccountNumber}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,verifyAccountNumber:true }))}
                />
                {formErrors.verifyAccountNumber && formOnBlur.verifyAccountNumber ? (
                  <div className="text-danger fs-7">{formErrors?.verifyAccountNumber}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Bank Name*"
                  name="bankName"
                  onChange={handleChange}
                  value={formValues.bankName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,bankName:true }))}
                />
                {formErrors.bankName && formOnBlur.bankName ? (
                  <div className="text-danger fs-7">{formErrors?.bankName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Branch Name*"
                  name="branchName"
                  onChange={handleChange}
                  value={formValues.branchName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,branchName:true }))}
                />
                {formErrors.branchName && formOnBlur.branchName ? (
                  <div className="text-danger fs-7">{formErrors?.branchName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="IFSC Code*"
                  name="ifscCode"
                  onChange={handleChange}
                  value={formValues.ifscCode}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,ifscCode:true }))}
                />
                {formErrors.ifscCode && formOnBlur.ifscCode ? (
                  <div className="text-danger fs-7">{formErrors?.ifscCode}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <select
                  className="form-select shadow-none py-2 inp px-2"
                  aria-label="Default select example"
                  name="state"
                  onChange={handleChange}
                  value={formValues.state}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,state:true }))}
                >
                  {data &&
                    data.map((item) => (
                      <option key={item?.label} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                </select>
              </div>
              {formErrors.state && formOnBlur.state ? (
                  <div className="text-danger fs-7">{formErrors?.state}</div>
                ) : (
                  ""
                )}
              <div className="mobileLabel  text-center">
                *All fields are mandatory
                {error ? (
                  <div className="text-danger fs-7">{error}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <button onClick={handleSubmit} className="otpBtnn text-center">
                  Submit for Verification
                </button>
              </div>
            </form>
            
          </div>
        </div>
      ) : (
        ""
      )}

      {/* when mobile , email and pan is verified and bank details are submitted but not verified */}
      {userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 &&
      userData?.PanVerified === 1 &&
      userData?.BankVerified === 0 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body pb-0 row mx-0 px-0 gap-2">
              <div className="col-12 verifyMobile verifyPanDetails">
                Bank Document
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account Holder</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accountholdername}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account No.</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accno}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">IFSC Code</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.ifsc}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Bank Name</div>
                  <div className="col-7 verifyMobileSub">
                    {bankDetails?.bankname}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Branch</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.bankbranch}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">State</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.state}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="w-50 mx-auto">
                  <img src={bankDetails?.image?bankDetails?.image:book} className="w-100" alt="" />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      {/* bank is verified */}
      {userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 &&
      userData?.PanVerified === 1 &&
      userData?.BankVerified === 1 ? (
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body pb-0 row mx-0 px-0 gap-2">
              <div className="col-12 verifyMobile verifyPanDetails">
                Bank Document Verified
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account Holder</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accountholdername}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account No.</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accno}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">IFSC Code</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.ifsc}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Bank Name</div>
                  <div className="col-7 verifyMobileSub">
                    {bankDetails?.bankname}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Branch</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.bankbranch}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">State</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.state}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="w-50 mx-auto">
                  <img src={bankDetails?.image?bankDetails?.image:book} className="w-100" alt="" />
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : (
        ""
      )}

      {userData?.mobileVerified === 1 &&
      userData?.emailVerified === 1 &&
      userData?.PanVerified === 1 &&
      userData?.BankVerified === 2 ? (<>
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner">
            <form className="card-body pb-0 row mx-0 px-0 gap-2">
              <div className="col-12 verifyMobile verifyPanDetails">
                Bank Document 
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account Holder</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accountholdername}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Account No.</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.accno}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">IFSC Code</div>
                  <div className="col-7 verifyMobileSub">{bankDetails?.ifsc}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody border-bottom-light">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Bank Name</div>
                  <div className="col-7 verifyMobileSub">
                    {bankDetails?.bankname}
                  </div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0  bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">Branch</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.bankbranch}</div>
                </div>
              </div>
              <div className="col-12 pt-2 px-0 bottomBody">
                <div className="row mx-0 align-items-center">
                  <div className="col-5 verifyMobile">State</div>
                  <div className="col-7 verifyMobileSub">{bankDetails.state}</div>
                </div>
              </div>
              <div className="col-12">
                <div className="w-50 mx-auto">
                  <img src={bankDetails?.image?bankDetails?.image:book} className="w-100" alt="" />
                </div>
              </div>
            </form>
          </div>
        </div>
        {/* Bank Document verification failed */}
        <div className="col-12 px-0">
          <div className="card border-0 mobileCard mobileCardInner verifyPanCard">
            <form className="card-body row mx-0 px-2 g-2">
              <div className="col-12 d-flex align-items-center gap-2">
                <div className="mobileImg">
                  <img src={blueBank} className="w-100" alt="" />
                </div>
                <div className="verifyMobile verifyPanDetails ">
                Bank Document verification failed , Try again!
                </div>
              </div>
              <div className="col-12 text-center">
                <div className="idCard d-inline-block">
                  <img src={typeof panImage === "object" ? url : panImage} className="h-100" alt="ID Card" />
                </div>
              </div>
              <div className="col-12">
                <label htmlFor="upload" className="otpBtnn text-center">
                  Upload Bank account Proof
                </label>
                <input
                  type="file"
                  id="upload"
                  className="form-control shadow-none inp"
                  hidden
                  onChange={onChangeFile}
                />
                <div className=" mobileLabel text-center">
                  Image size up to 4mb*
                </div>
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Account Holder Name*"
                  name="accountHolderName"
                  onChange={handleChange}
                  value={formValues.accountHolderName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,accountHolderName:true }))}
                />
              </div>
              {formErrors.accountHolderName && formOnBlur.accountHolderName ? (
                  <div className="text-danger">{formErrors?.accountHolderName}</div>
                ) : (
                  ""
                )}
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Account Number*"
                  name="accountNumber"
                  onChange={handleChange}
                  value={formValues.accountNumber}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,accountNumber:true }))}
                />
                {formErrors.accountNumber && formOnBlur.accountNumber ? (
                  <div className="text-danger fs-7">{formErrors?.accountNumber}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Verify Account Number*"
                  name="verifyAccountNumber"
                  onChange={handleChange}
                  value={formValues.verifyAccountNumber}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,verifyAccountNumber:true }))}
                />
                {formErrors.verifyAccountNumber && formOnBlur.verifyAccountNumber ? (
                  <div className="text-danger fs-7">{formErrors?.verifyAccountNumber}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Bank Name*"
                  name="bankName"
                  onChange={handleChange}
                  value={formValues.bankName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,bankName:true }))}
                />
                {formErrors.bankName && formOnBlur.bankName ? (
                  <div className="text-danger fs-7">{formErrors?.bankName}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="Branch Name*"
                  name="branchName"
                  onChange={handleChange}
                  value={formValues.branchName}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,branchName:true }))}
                />
                {formErrors.branchName && formOnBlur.branchName ? (
                  <div className="text-danger fs-7">{formErrors?.ifscCode}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control shadow-none inp"
                  placeholder="IFSC Code*"
                  name="ifscCode"
                  onChange={handleChange}
                  value={formValues.ifscCode}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,ifscCode:true }))}

                />
                {formErrors.ifscCode && formOnBlur.ifscCode ? (
                  <div className="text-danger fs-7">{formErrors?.ifscCode}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <select
                  className="form-select shadow-none py-2 inp px-2"
                  aria-label="Default select example"
                  name="state"
                  onChange={handleChange}
                  value={formValues.state}
                  onBlur={()=>setFormOnBlur(prev=>({ ...prev,state:true }))}

                >
                  {data &&
                    data.map((item) => (
                      <option key={item?.label} value={item?.value}>
                        {item?.label}
                      </option>
                    ))}
                </select>
                {formErrors.state && formOnBlur.state ? (
                  <div className="text-danger fs-7">{formErrors?.state}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="mobileLabel  text-center">
                *All fields are mandatory
                {error ? (
                  <div className="text-danger fs-7">{error}</div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-12">
                <button onClick={handleSubmit} className="otpBtnn text-center">
                  Submit for Verification
                </button>
              </div>
            </form>
          </div>
        </div>
        </>
      ) : (
        ""
      )}
      
    </div>
  );
};

export default Bank;
